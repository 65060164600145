import * as React from "react";
import { Button, Container , Offcanvas, Row , Col, Stack, ToastContainer , Toast, Spinner, Tab, Nav} from "react-bootstrap";
import LeftSidebar from "../components/admin/LeftSidebar";
import {  CheckCircleFill, Circle, List, SendFill } from 'react-bootstrap-icons';
import { useRefreshEmitter } from "../components/RefreshEmitter.js";
import Header from "../components/admin/header";
import SearchUser from "../components/admin/tools/SearchUser.js";
import { useParams } from "react-router-dom";
import UserProfile from "../components/admin/userprofile/UserProfile.js";
import PasswordAndSecurity from "../components/admin/userprofile/PasswordAndSecurity.js";
import BackgroundChecks from "../components/admin/userprofile/BackgroundChecks.js";
import ShieldScore from "../components/admin/userprofile/ShieldScore.js";
import MonitoringActions from "../components/admin/userprofile/MonitoringActions.js";
import TermsAndConditions from "../components/admin/userprofile/TermsAndConditions.js";
import PrivacyAndPersonal from "../components/admin/userprofile/PrivacyAndPersonal.js";
import AdminNotes from "../components/admin/userprofile/AdminNotes.js";
import UserMetadata from "../components/admin/userprofile/UserMetadata.js";
import LegacyLinking from "../components/admin/userprofile/LegacyLinking.js";
import AccountDetails from "../components/admin/enterprise/AccountDetails.js";
import Branding from "../components/admin/enterprise/Branding.js";
import Team from "../components/admin/enterprise/Team.js";
import CRAPackages from "../components/admin/enterprise/CRAPackages.js";
import api from '../io/admin.js';
import { useAuth } from "../components/Auth.js";
import AccountStatus from "../components/admin/userprofile/AccountStatus.js";
import NetworkManagement from "../components/admin/enterprise/NetworkManagement.js";

function Admin() {

	let params = useParams();
	const emitter = useRefreshEmitter();
	const auth = useAuth();
	const [show, setShow] = React.useState(false);
	const [ formdirty , setFormDirty ] = React.useState(false);
	const [ successevent , setSuccessEvent ] = React.useState(false);
	const [ saveinprogress , setSaveInProgress ] = React.useState(false);
	const [ dashboard , setDashboard ] = React.useState({});
	const [ enterpriseflag , setEnterpriseFlag ] = React.useState(false);


	function handleClose()
	{
		setShow(false);
	}
	function handleShow()
	{
		setShow(true);
	}

	async function SaveEvent()
	{
		emitter.Emit('admin:saveuser');
		setSaveInProgress(true);
	}

	function DiscardChanges()
	{
		window.location.reload();
	}

	async function GetNewDashboardUrl()
	{
		let response = await api.getdashboardurl();

		if(response.success)
		{
			setDashboard({
				url : response.dashboardurl,
				expires : new Date().getTime() + (3 * 60 * 1000)
			});
		}
	}

	function UpdateEnterpriseFlag( newflag )
	{
		setEnterpriseFlag(newflag);
	}

	React.useEffect(() => {

		if(formdirty === false)
		{
			setSaveInProgress(false);
		}

	},[formdirty]);

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		if((params.panel === undefined || params.panel === 'tools') && dashboard.expires === null || dashboard.expires === undefined || dashboard.expires < new Date().getTime())
		{
			( async () => {
				await GetNewDashboardUrl();
			})();
		}

		if(window.clarity)
		{
			window.clarity('set','adminid',auth?.user?.userid);
		}

	},[params.panel]);
	/* eslint-enablereact-hooks/exhaustive-deps */


	return (
		<>
			<Header />
			<Button className="mobile-menu d-block d-md-none" variant="link" onClick={handleShow}>
				<List/>
			</Button>
			<Container fluid style={{backgroundColor:"#e5e5e5",height:"100%"}}>
				<Row>
					<Col md={4} lg={3} className="l-sidebar">
						<Offcanvas show={show} onHide={handleClose} responsive="md">
							<Offcanvas.Header closeButton />
							<Offcanvas.Body>
								<LeftSidebar onClick={handleClose}/>
							</Offcanvas.Body>
						</Offcanvas>
					</Col>
					<Col md={8} lg={9}>
						<Container className="p-1 pt-3">
							<SearchUser />
						</Container>
						<iframe src={dashboard.url} style={(params.panel === undefined || params.panel === 'tools') ? {width:"100%",height:"1120px"} : {width:"100%",height:"0px"} } />
						{params.panel === 'user' && 
							<>
								<Tab.Container defaultActiveKey='personal'>
									<Nav variant="tabs" className="network-tabs p-1">
										<Nav.Item>
											<Nav.Link eventKey="personal">Personal Profile</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey="enterprise">
												{ enterpriseflag === false && <Circle color="gray" /> }
												{ enterpriseflag === true && <CheckCircleFill color="green" /> }
												&nbsp;Enterprise Profile</Nav.Link>
										</Nav.Item>
									</Nav>
									<Tab.Content className="p-0">
										<Tab.Pane eventKey='personal'>
											<Stack className="gap-3 pt-0 pb-3 ps-1">
												<AccountStatus setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
												<UserProfile setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent}/>
												<LegacyLinking setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
												<PasswordAndSecurity setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
												<ShieldScore setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
												<BackgroundChecks setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
												<MonitoringActions  setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
												<PrivacyAndPersonal setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
												<TermsAndConditions setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
												<AdminNotes setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
												<UserMetadata setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
											</Stack>
										</Tab.Pane>
										<Tab.Pane eventKey='enterprise'>
											<Stack className="gap-3 pt-0 pb-3 ps-1" style={{minWidth:'1050px'}}>
												<AccountDetails setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} setEnterpriseFlagFunction={UpdateEnterpriseFlag} />
												{enterpriseflag === true && <>
													<Branding setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
													<Team setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
													<CRAPackages setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
													<NetworkManagement setFormDirtyFunction={setFormDirty} setSuccessFunction={setSuccessEvent} />
												</>}
											</Stack>
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
								<ToastContainer position='bottom-center' style={{zIndex:1000}} className="position-fixed p-4">
									<Toast bg='primary' show={formdirty} style={{width:'465px'}}>
										<Toast.Body style={{textAlign:'center',color:'#FFFFFF',fontWeight:'bold'}}>Save Profile Changes ? 
											{saveinprogress === false && <><Button variant="secondary" onClick={DiscardChanges} style={{marginLeft:'90px'}} >Nevermind</Button> <Button onClick={SaveEvent} style={{marginLeft:'15px'}}>Save</Button></>}
											{saveinprogress === true && <Button variant="primary" style={{marginLeft:'100px'}}>&nbsp;&nbsp;<Spinner as="span" size="sm" animation="border" />&nbsp;&nbsp;</Button>}
										</Toast.Body>
									</Toast>
									<Toast bg='success' show={successevent !== false}>
										<Toast.Body style={{textAlign:'center',color:'#FFFFFF',fontWeight:'bold'}}>
											{successevent === 'resetpassword' && <>Reset Password Sent <SendFill /></>}
										</Toast.Body>
									</Toast>
								</ToastContainer>
							</>
						}
					</Col>
				</Row>
			</Container>
		</>
	);

}

export default Admin