import * as React from "react";
import { Card, Collapse , Button, Row , Col, Container, Alert} from "react-bootstrap";
import { CheckCircleFill, ChevronDown, ChevronRight, ExclamationCircleFill, XCircleFill } from "react-bootstrap-icons";
import ICScore from '../../ICScore';
import { useLocation } from "react-router-dom";
import { format as dateFormat , parseISO as dateParseISO , isValid as dateValid , isFuture as dateInFuture , isToday as dateIsToday , isPast as dateInPast  } from 'date-fns';
import api from '../../../io/admin';
import { useRefreshEmitter } from "../../RefreshEmitter";


function ShieldScore()
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ userprofile , setUserProfile ] = React.useState({});
	const [ panelshow , setPanelShow ] = React.useState(true);

	async function LoadCurrentScore( userid )
	{
		let response = await api.getcurrentscore( userid );

		if(response.success === true)
		{

			if(response.profile.effective_dts && !dateValid(response.profile.effective_dts) && response.profile?.effective_dts?.length > 0)
			{
				try{
					response.profile.effective_dts = dateParseISO(response.profile.effective_dts);
				}
				catch(e)
				{
					console.warn(e);
					response.profile.effective_dts = null;
				}
			}
			if(response.profile.last_update_dts && !dateValid(response.profile.last_update_dts) && response.profile?.last_update_dts?.length > 0)
			{
				try{
					response.profile.last_update_dts = dateParseISO(response.profile.last_update_dts);
				}
				catch(e)
				{
					console.warn(e);
					response.profile.last_update_dts = null;
				}
			}
			if(response.profile.expiration_dts && !dateValid(response.profile.expiration_dts) && response.profile?.expiration_dts?.length > 0)
			{
				try{
					response.profile.expiration_dts = dateParseISO(response.profile.expiration_dts);
				}
				catch(e)
				{
					console.warn(e);
					response.profile.expiration_dts = null;
				}
			}


			setUserProfile(response.profile);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setUserProfile({
				userid : 1000,
				legalfirstname : 'Jonathan',
				legallastname : 'Smith',
				currenticstatus : 'IC01',
				effective_dts : new Date(),
				last_update_dts : new Date(),
				expiration_dts : new Date('2024-01-10 10:00:00')
			});
		}
		else
		{
			console.warn(response);
			setUserProfile({});
		}
	
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setUserProfile({});

		( async () => {
			await LoadCurrentScore( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadCurrentScore( userid );
			})();
		}
	},[emitter.eventname,hash]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;ShieldScore</Button>


			<Collapse in={panelshow}>
				<Container style={{textAlign:'center'}} id="admin-score-block">
					<Row>
						<Col>Legal Name</Col>
						<Col xs={2}>Current Score</Col>
						<Col xs={2}>Effective Date</Col>
						<Col>Last Update</Col>
						<Col>Expiration Date</Col>
					</Row>
					<Row>
						<Col style={{paddingTop:'10px'}}>{userprofile.legalfirstname} {userprofile.legallastname}</Col>
						<Col xs={2} style={{paddingTop:'10px'}} className='score-column'><ICScore score={userprofile.currenticstatus}/></Col>
						<Col xs={2} style={{paddingTop:'16px'}}>{ (userprofile.effective_dts && dateValid(userprofile.effective_dts) ? dateFormat(userprofile.effective_dts,"MM/dd/yyyy") :  null )}</Col>
						<Col style={{paddingTop:'16px'}}>{ (userprofile.last_update_dts && dateValid(userprofile.last_update_dts) ? dateFormat(userprofile.last_update_dts,"MM/dd/yyyy hh:mm a") : null )}</Col>
						<>
							{ (userprofile.expiration_dts && dateValid(userprofile.expiration_dts) && dateInFuture(userprofile.expiration_dts) ) ? <Col><Alert variant="success"><CheckCircleFill /> {dateFormat(userprofile.expiration_dts,"MM/dd/yyyy")} </Alert></Col> : null }
							{ (userprofile.expiration_dts && dateValid(userprofile.expiration_dts) && dateIsToday(userprofile.expiration_dts) ) ? <Col><Alert variant="warning"><ExclamationCircleFill /> {dateFormat(userprofile.expiration_dts,"MM/dd/yyyy")}</Alert></Col> : null }
							{ (userprofile.expiration_dts && dateValid(userprofile.expiration_dts) && dateInPast(userprofile.expiration_dts) && !dateIsToday(userprofile.expiration_dts) ) ? <Col><Alert variant="danger"><XCircleFill /> {dateFormat(userprofile.expiration_dts,"MM/dd/yyyy")}</Alert></Col> : null }

							{ ( userprofile.expiration_dts === null || userprofile.expiration_dts === undefined) && <Col></Col>}
						</>
					</Row>
				</Container>
			</Collapse>
		</Card>

	);

}
export default ShieldScore;