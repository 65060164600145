import * as React from "react";
import { Col , Row , CloseButton, Card } from "react-bootstrap";
import WelcomeArt from "../../assets/welcome_banner.png"
import { useBranding } from "../Branding";

function WelcomeBanner() {

	const branding = useBranding();

	return (
		<Card className="p-4">
			<div className="position-absolute closebtn">
				<CloseButton/>
			</div>
			<Row>
				<Col md={12} lg={5} className="d-none d-lg-block">
					<img src={WelcomeArt} alt="Welcome to ShieldHub" className="d-block banner-hero"></img>
				</Col>
				<Col lg={7} sm={12}>
					<h2 className="display-6 banner-header">Welcome to ShieldHub</h2>
					<p>ShieldHub provides independent contractors a place to securely purchase and share their background check data with clients and customers. Getting started is is easy:</p>
					<ol>
						<li>Purchase a comprehensive background check solution from an accredited CRA Partner below</li>
						<li>Receive a ShieldID<sup>TM</sup> with your results that you can share with clients and customers</li>
						<li>Clients can lookup your ShieldScore<sup>TM</sup> and invite you to their trusted network</li>
					</ol>
					<p style={{color:branding.PrimaryColor}}><b>Get started by purchasing an eligible background check package from our Credit Reporting Agency partner below.</b></p>
				</Col>
			</Row>
		</Card>
	);
}

export default WelcomeBanner;