import * as api from './apibase';
import axios from 'axios';

async function getenterpriseprofile( userid )
{
	return await api.post('/admin/getenterpriseprofile',{userid});
}

async function saveenterpriseprofile( profileobject )
{
	return await api.post('/admin/saveenterpriseprofile', profileobject);
}

async function checkdomainavailable( domainrequest )
{
	return await api.post('/admin/checkdomainavailable', domainrequest );
}

async function listenterpriseteam( companyid )
{
	return await api.post('/admin/listenterpriseteam', {companyid});
}

async function saveenterprisepackageselections( companyid , packages )
{
	return await api.post('/admin/saveenterprisepackageselections', { companyid , packages } );
}

async function getbrandinguploadurl( companyid )
{
	return await api.get('/admin/uploadenterpriselogo', { companyid } );
}

async function uploadbrandinglogotos3( posturl , imagecontent )
{

	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.put(posturl,imagecontent);

		if(response.status === 200)
		{
			apiresponse.success = true;
		}
	}
	catch (e){
		console.log(e);
	}


	return apiresponse;	

}

async function confirmbrandingupload( request )
{
	return await api.post('/admin/uploadenterpriselogo', request );
}

async function packagesearch( request )
{
	return await api.post('/admin/packagesearch', request);
}

async function switchenterpriseowner( companyid , newuserid )
{
	return await api.post('/admin/switchenterpriseowner', {companyid, newuserid});
}

async function saveenterpriseteam( request )
{
	return await api.post('/admin/saveenterpriseteam', request);
}

async function checkinviteuser( emailaddress )
{
	return await api.post('/admin/checkenterpriseteaminvite',{emailaddress});
}

async function inviteteammember( request )
{
	return await api.post('/admin/inviteenterpriseteammember', request);
}

async function resendteaminvite( companyid , memberid )
{
	return await api.post('/admin/resendenterpriseteaminvite', {companyid,memberid});
}
async function sendenterpriseinvites( request )
{
	return await api.post('/admin/sendenterpriseinvites', request);
}

export default { 
	getenterpriseprofile,
	saveenterpriseprofile,
	checkdomainavailable,
	listenterpriseteam,
	saveenterprisepackageselections,
	getbrandinguploadurl,
	uploadbrandinglogotos3,
	confirmbrandingupload,
	packagesearch,
	switchenterpriseowner,
	saveenterpriseteam,
	checkinviteuser,
	inviteteammember,
	resendteaminvite,
	sendenterpriseinvites,
};