import * as React from "react";
import { Col, Card, Row , Form , Button , Collapse } from "react-bootstrap";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";
import useSessionStorage from '@twipped/hooks/useSessionStorage';
import api from "../../../io/profile"

function PrivacyAndSharing( props )
{
	const [ sessionProfile ] = useSessionStorage('ShieldHubProfile',{});
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ profile , setProfile ] = React.useState(sessionProfile);

	const formref = React.createRef();

	async function GetProfileDetails()
	{
		let response = await api.getmyprofile();

		if(response.success)
		{
			setProfile(response.profile);
		}
	}	

	async function SaveProfile()
	{

		props.SaveEvent('PrivacyAndSharing','startsave');

		let profileobject = { ...profile };

		let formData = new FormData(formref.current);

		profileobject.network_sharing_master = ( formData.get('network_sharing_master') === 'YES' ? 'YES' : 'NO' );
		profileobject.network_autoacceptinvite = ( formData.get('network_autoacceptinvite') === 'YES' ? 'YES' : 'NO' );
		profileobject.network_searchresults = ( formData.get('network_searchresults') === 'YES' ? 'YES' : 'NO' );
		// profileobject.network_shieldscore = ( formData.get('network_shieldscore') === 'YES' ? 'YES' : 'NO' );
		// profileobject.network_profileimage = ( formData.get('network_profileimage') === 'YES' ? 'YES' : 'NO' );
		// profileobject.network_fullreport = ( formData.get('network_fullreport') === 'YES' ? 'YES' : 'NO' );

		await api.saveprofileinfo(profileobject);

		setProfile(profileobject);

		props.SaveEvent('PrivacyAndSharing','endsave');
	}

	React.useEffect( () => {

		( async () => {
			await GetProfileDetails();
		})();

	},[]);

	return (

		<Card className="p-3 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;Privacy &amp; Personal Data</Button>
			<Collapse in={panelshow}>
				<Form ref={formref}>
					<Row className="feature-row">
						<Col xs={8} sm={8}>
							<h6>ShieldID<sup>TM</sup> Network Master Switch</h6>
							<p className="small">Enable data sharing across your trusted network.  Disabling sharing here will disable sharing for your network. This feature is enabled by default.</p>
						</Col>
						<Col xs={{span:1, offset:2}}>
							<Form.Check 
								type="switch" 
								style={{transform:"scale(1.3)"}} 
								checked={profile.network_sharing_master !== 'NO'}
								name="network_sharing_master" 
								value="YES"
								onChange={SaveProfile}
							/>
						</Col>
					</Row>
					<Row className="feature-row">
						<Col xs={8}>
							<h6>Auto Accept Network Invites</h6>
							<p className="small">Automatically accept network invites.  Please be aware that automatically enabling this feature may allow you to share scores with unintended users.</p>
						</Col>
						<Col xs={{span:1, offset:2}}>
							<Form.Check 
								type="switch" 
								style={{transform:"scale(1.3)"}} 
								checked={profile.network_autoacceptinvite === 'YES'} 
								name="network_autoacceptinvite" 
								value="YES"
								onChange={SaveProfile}
							/>
						</Col>
					</Row>
					<Row className="feature-row">
						<Col xs={8}>
							<h6>ShieldID<sup>TM</sup> (ABC#) Search Results</h6>
							<p className="small">Automatically share your ShieldScore (IC# )results with users searching for your ShieldID (ABC#). Disabling this feature will require searches to request consent before your score is shared. This may likely cause delays for users seeking to verify your ShieldScore. This feature is enabled by default.</p>
						</Col>
						<Col xs={{span:1, offset:2}}>
							<Form.Check 
								type="switch" 
								style={{transform:"scale(1.3)"}} 
								checked={profile.network_searchresults !== 'NO'}
								name="network_searchresults" 
								value="YES"
								onChange={SaveProfile}
							/>
						</Col>
					</Row>
					{/* <Row className="feature-row">
						<Col xs={12} sm={8}>
							<h6 style={{fontWeight:"bold"}}>Default Sharing Preferences</h6>
							<p>Set default settings for those items you which to share with clients and partners.  You can override these settings in your network connections. ShieldScore sharing settings are enabled by default.</p>
						</Col>
						<Col xs={12} sm={4}>
							<Stack>
								<FormCheck>
									<FormCheck.Input 
										type="checkbox" 
										style={{transform:"scale(1.3)"}} 
										checked={profile.network_shieldscore !== 'NO'} 
										name="network_shieldscore" 
										value="YES"
										onChange={SaveProfile}
									/>
									<FormCheck.Label>
										<p className="mb-0 feature-title">ShieldScore<sup>SM</sup></p>
										<p className="feature-desc">Share your ShieldScore by default</p>
									</FormCheck.Label>
								</FormCheck>
								<FormCheck>
									<FormCheck.Input 
										type="checkbox" 
										style={{transform:"scale(1.3)"}} 
										checked={profile.network_profileimage !== 'NO'} 
										name="network_profileimage" 
										value="YES"
										onChange={SaveProfile}
									/>
									<FormCheck.Label>
										<p className="mb-0 feature-title">Profile Image</p>
										<p className="feature-desc">Share your Profile Image by default</p>
									</FormCheck.Label>
								</FormCheck>
								<FormCheck>
									<FormCheck.Input 
										type="checkbox" 
										style={{transform:"scale(1.3)"}} 
										checked={profile.network_fullreport === 'YES'} 
										name="network_fullreport" 
										value="YES"
										onChange={SaveProfile}
									/>
									<FormCheck.Label>
										<p className="mb-0 feature-title">Full Background Check Report</p>
										<p className="feature-desc">This allows clients to download a PDF of your report from within their ShieldHub account.</p>
									</FormCheck.Label>
								</FormCheck>
							</Stack>
						</Col>
					</Row> */}
				</Form>
			</Collapse>
		</Card>

	);
}

export default PrivacyAndSharing;