import * as React from "react";
import { Modal , ProgressBar } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import SelectPackage from "./purchasecomponents/SelectPackage";
import CompletePurchase from "./purchasecomponents/CompletePurchase";
import PuchaseReceipt from "./purchasecomponents/PurchaseReceipt";
import HelpMeChoose from "./purchasecomponents/HelpMeChoose";

function PurchaseWorkflow() {

	const params = useParams();
	const navigate = useNavigate();

	const [ pagetitles , setPageTitles ] = React.useState({'selectpackage':{title : 'Select Background Check',progressvalue : 10,}});

	function exitWorkflow()
	{
		navigate('/user');
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		setPageTitles({
			'selectpackage' : {
				title : 'Select Background Check',
				progressvalue : 10,
			},
			'helpmechoose' :{
				title : 'Help Me Choose',
				progressvalue : 10,
			},
			'completepurchase' : {
				title : 'Complete Purchase',
				progressvalue : 50,
			},
			'purchasereceipt' : {
				title : 'Complete Purchase',
				progressvalue : 100,
			}
		});

	},[]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<Modal show={true} fullscreen='lg-down' size='xl' centered onHide={exitWorkflow} animation={false} backdrop='static'>
			<Modal.Header closeButton style={{backgroundColor:'#2A2D40',color:'#FFFFFF'}} closeVariant="white" className="rounded-0">
				<Modal.Title>{ ( params.workflowstep === undefined || (pagetitles[params.workflowstep] === undefined ) ? pagetitles['selectpackage'].title : pagetitles[params.workflowstep].title )}</Modal.Title>
			</Modal.Header>
			<ProgressBar variant="primary" now={( params.workflowstep === undefined || (pagetitles[params.workflowstep] === undefined ) ? pagetitles['selectpackage'].progressvalue : pagetitles[params.workflowstep].progressvalue )} className="rounded-0"  style={{marginTop:'-10px',height:'5px'}}/>			
			<Modal.Body style={{backgroundColor:'#E9ECEF'}}>
				{(params.workflowstep === undefined || pagetitles[params.workflowstep] === undefined || params.workflowstep === 'selectpackage' ) && <SelectPackage />}
				{params.workflowstep === 'helpmechoose' && <HelpMeChoose />}
				{params.workflowstep === 'completepurchase' && <CompletePurchase />}
				{params.workflowstep === 'purchasereceipt' && <PuchaseReceipt /> }
			</Modal.Body>
			<Modal.Footer style={{backgroundColor:'#2A2D40',color:'#FFFFFF'}} className="rounded-0">
				Powered by ShieldHub
			</Modal.Footer>
		</Modal>
	);
}
export default PurchaseWorkflow;