import * as api from './apibase';

async function startpurchase( packageid )
{
	return await api.post('/application/startpurchase',{packageid});
}

async function setpurchaseconfirmed( purchaseid )
{
	return await api.post('/application/setpurchaseconfirmed', {purchaseid});
}

async function getpurchase( purchaseid )
{
	return await api.post('/application/getpurchase', {purchaseid});
}

async function downloadreceipt( purchaseid , appid )
{
	return await api.get('/application/downloadreceipt', { purchaseid , appid });
}

export default { 	
	startpurchase,
	setpurchaseconfirmed,
	getpurchase,
	downloadreceipt,
};