import * as React from "react";
import { Button, Card, Col, Collapse, Container, Dropdown, Row } from "react-bootstrap";
import { ChevronDown, ChevronRight, Download, Gear, Receipt } from "react-bootstrap-icons";
import { useBranding } from "../../Branding";
import { format as dateFormat , parseJSON as dateParseJSON , isValid as dateValid } from "date-fns";
import api from "../../../io/profile";
import workflowapi from '../../../io/workflow';
import FileDownloadModal from "../../FileDownloadModal";


function Purchases()
{
	const branding = useBranding();
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ bgchecks , setBgChecks ] = React.useState([]);
	const [ downloadModal , setDownloadModal ] = React.useState(false);
	const [ downloadlink , setDownloadlink ] = React.useState(false);
	const [ downloaderror , setDownloaderror ] = React.useState(false);
	const [ receiptpdfcontenturl , setReceiptContent ] = React.useState(false);
	const [ receiptpdfname , setReceiptpdfname ] = React.useState('shieldhub_receipt.pdf');

	async function LoadBgChecks()
	{
		let response = await api.getpurchases();
		if(response.success)
		{
			response.purchases = response.purchases.map((purchase) => {
				if(purchase.dts)
				{
					try {
						purchase.dts = dateParseJSON(purchase.dts);
					}
					catch (e){
						console.warn(e);
						purchase.dts = null;
					}
				}
				return purchase;
			});

			setBgChecks(response.purchases);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setBgChecks([
				{
					id : 1000,
					cra_name : 'ClearStar',
					dts : new Date(),
					composite_status : 'In Progress',
					composite_status_code : 'waitcra',
					expiration_date : new Date(),
					package_descrip : 'Non New York State 1-Year Package with Continuous Monitoring',
					amount : 50,
					paid_amount : 25,
					refunded_amount : 25,
					stripe_transactions : [
						{
							id : 'pm_1POmtPRwQeGUavhlfJuOm9cI',
							dts : dateParseJSON('2024-06-09T13:05:06.000Z'),
							type : 'CHARGE',
							amount : 50,
							method : 'VISA 4242'
						},
						{
							id : 9001,
							dts : dateParseJSON('2024-06-10T12:15:06.000Z'),
							type : 'REFUND',
							amount : 25,
							method : 'VISA 4242'
						},
					],
				},
				{
					id : 1000,
					cra_name : 'ClearStar',
					dts : new Date(),
					composite_status : 'Archived',
					composite_status_code : 'archived',
					expiration_date : new Date(),
					package_descrip : 'Non New York State 1-Year Package with Continuous Monitoring',
					amount : 50,
					paid_amount : 25,
					refunded_amount : 25,
					stripe_transactions : [
						{
							id : 'pm_1POmtPRwQeGUavhlfJuOm9cI',
							dts : dateParseJSON('2024-06-09T13:05:06.000Z'),
							type : 'CHARGE',
							amount : 50,
							method : 'VISA 4242'
						},
						{
							id : 9001,
							dts : dateParseJSON('2024-06-10T12:15:06.000Z'),
							type : 'REFUND',
							amount : 25,
							method : 'VISA 4242'
						},
					],
				},
			]);
		}
	}

	async function DownloadClick( appid )
	{
		setDownloadModal(true);
		let response = await api.getDownloadLink( appid );

		if(response.success)
		{
			setDownloadlink(response.downloadlink);
		}
		else
		{
			setDownloaderror('Error: Report failure');
		}
	}

	async function DownloadReceipt( appid )
	{
		setDownloadModal(true);
		let response = await workflowapi.downloadreceipt( null , appid );
		if(response.success)
		{
			let now = parseInt(Date.now()/1000);
			setReceiptpdfname(`shieldhub_receipt_${now}.pdf`);
			setReceiptContent(`data:application/pdf;base64,${response.receipt_body}`);
		}
	}


	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		( async () => {
			await LoadBgChecks( );
		})();

	},[]);

	return (
		<>
			<Card className="p-4 d-inline-block">
				<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
					{panelshow && <ChevronDown/>}
					{!panelshow && <ChevronRight />}
					&nbsp;Purchases</Button>
				<Collapse in={panelshow}>
					<Container style={{textAlign:'center'}}>
						{bgchecks && bgchecks.length > 0 && 
							<>
								<Row style={{color:branding.PrimaryColor,textTransform:'uppercase'}} className="p-2">
									<Col md={2}>Provider</Col>
									<Col md={4}>Package</Col>
									<Col md={2}>Start Date</Col>
									<Col md={2}>Status</Col>
									<Col xs={1}>Amount</Col>
									<Col xs={1}></Col>
								</Row>
								{bgchecks && bgchecks.map( (bgcheck) => {

									return (
										<Row key={bgcheck.id} className='p-2' style={{ backgroundColor:( bgcheck.composite_status_code === 'archived' ? '#F7F7F7' : 'none' ),color:( bgcheck.composite_status_code === 'archived' ? '#2A2D40' : 'inherit' ) }}>
											<Col md={2} className='text-start'>{bgcheck.cra_name}</Col>
											<Col md={4} className='text-start'>{bgcheck.package_descrip}</Col>
											<Col md={2}>{(bgcheck.dts && dateValid(bgcheck.dts) ) ? dateFormat(bgcheck.dts,'MM/dd/yyyy') : null }</Col>
											<Col md={2}>{bgcheck.composite_status}</Col>
											<Col xs={1} >${bgcheck.amount}</Col>
											<Col xs={1}>
												<Dropdown>
													<Dropdown.Toggle variant="primary" size="sm"><Gear/></Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item onClick={ async () => {
															await DownloadClick(bgcheck.id);
														}} disabled={(bgcheck.composite_status_code !== 'done')} ><Download /> Download Report</Dropdown.Item>
														{bgcheck.stripe_transactions && bgcheck.stripe_transactions.length > 0 && <Dropdown.Item onClick={ async () => {
															await DownloadReceipt(bgcheck.id);
														}}><Receipt /> Download Receipt</Dropdown.Item>}
													</Dropdown.Menu>
												</Dropdown>
											</Col>
										</Row>
									);

								})}
							</>
						}
					</Container>

				</Collapse>
			</Card>
			<FileDownloadModal
				show={downloadModal}
				downloadlink={ (downloadlink !== false && downloadlink.length > 10 ? downloadlink : ( (receiptpdfcontenturl !== false && receiptpdfcontenturl.length > 10) ? receiptpdfcontenturl : false )  ) }
				downloadtext={ (downloadlink !== false && downloadlink.length > 10 ? 'Download Report' : ( (receiptpdfcontenturl !== false && receiptpdfcontenturl.length > 10) ? 'Download Receipt' : null )  ) }
				downloaderror={downloaderror}
				downloadname={receiptpdfname}
				CancelMethod={() => {
					setDownloadModal(false);
					setDownloadlink(false);
					setReceiptContent(false);
				}}
			/>			
		</>
	)
}
export default Purchases;