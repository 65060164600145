import * as React from "react";
import { Card, Collapse , Button, Form , Row , Col, Container, Dropdown, Modal, InputGroup, Alert } from "react-bootstrap";
import { ChevronDown, ChevronRight, CurrencyDollar, Download, ExclamationTriangleFill, Gear, Receipt, Stripe , CheckCircleFill } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import {format as dateFormat , parseJSON as dateParseJSON , isValid as dateValid } from "date-fns";
import api from '../../../io/admin';
import { useBranding } from "../../Branding";
import ICScore from "../../ICScore";
import FileDownloadModal from '../../FileDownloadModal';
import { useRefreshEmitter } from "../../RefreshEmitter";



function BackgroundChecks()
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ bgchecks , setBgChecks ] = React.useState([]);
	const [ orderdetailsmodal , setOrderdetailsmodal ] = React.useState(false);
	const [ orderdetailsobject , setOrderdetailsobject ] = React.useState({});
	const [ orderdetailsrefundtoggle , setDetailsrefundtoggle ] = React.useState(false);
	const [ orderedtailsrefundtype , setDetailsrefundtype ] = React.useState(false);
	const [ refunddetails , setRefundDetails ] = React.useState({});
	const [ overrefunderror , setOverRefundError ] = React.useState(false);
	const [ refundbuttonactive , setRefundbuttonactive ] = React.useState(false);
	const [ refundsuccess , setRefundSuccess ] = React.useState(false);
	const [ refundinprogress , setRefundinprogress ] = React.useState(false);
	const [ downloadModal , setDownloadModal ] = React.useState(false);
	const [ downloadlink , setDownloadlink ] = React.useState(false);
	const [ downloaderror , setDownloaderror ] = React.useState(false);
	const [ receiptpdfcontenturl , setReceiptContent ] = React.useState(false);
	const [ receiptpdfname , setReceiptpdfname ] = React.useState('shieldhub_receipt.pdf');

	const branding = useBranding();
	const otherrefundamountref = React.createRef();

	const formRef = React.createRef(null);

	async function LoadBgChecks( userid )
	{
		let response = await api.getbgchecks( userid );
		
		if(response.success === true)
		{
			let bgchecks = response.bgchecks.map( (bgcheck) => {

				if(bgcheck.dts)
				{
					try {
						bgcheck.dts = dateParseJSON(bgcheck.dts);
					}
					catch (e){
						console.warn(e);
						bgcheck.dts = null;
					}
				}
				if(bgcheck.expiration_date)
				{
					try {
						bgcheck.expiration_date = dateParseJSON(bgcheck.expiration_date);
					}
					catch (e){
						console.warn(e);
						bgcheck.expiration_date = null;
					}
				}

				if(bgcheck.stripe_transactions && bgcheck.stripe_transactions.length > 0)
				{
					bgcheck.stripe_transactions = bgcheck.stripe_transactions.map( (transaction) => {

						if(transaction.dts)
						{
							try {
								transaction.dts = dateParseJSON(transaction.dts);
							}
							catch (e){
								console.warn(e);
								transaction.dts = null;
							}
						}

						return transaction;

					});
				}

				return bgcheck;
			});

			setBgChecks(bgchecks);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setBgChecks([
				{
					id : 1000,
					cra_name : 'ClearStar',
					dts : new Date(),
					composite_status : 'In Progress',
					composite_status_code : 'done',
					expiration_date : new Date(),
					package_descrip : 'Non New York State 1-Year Package with Continuous Monitoring',
					amount : 50,
					paid_amount : 25,
					refunded_amount : 25,
					stripe_transactions : [
						{
							id : 'pm_1POmtPRwQeGUavhlfJuOm9cI',
							dts : dateParseJSON('2024-06-09T13:05:06.000Z'),
							type : 'CHARGE',
							amount : 50,
							method : 'VISA 4242'
						},
						{
							id : 9001,
							dts : dateParseJSON('2024-06-10T12:15:06.000Z'),
							type : 'REFUND',
							amount : 25,
							method : 'VISA 4242'
						},
					],
				},
				{
					id : 1001,
					cra_name : 'ClearStar',
					dts : new Date(),
					composite_status : 'In Progress',
					expiration_date : dateParseJSON("2023-10-30T15:51:48.012Z"),
					package_descrip : 'Non New York State 1-Year Package with Continuous Monitoring',
					amount : 50,
					paid_amount : 50,
					refunded_amount : 0,
					stripe_transactions : [
						{
							id : 'pm_1POmtPRwQeGUavhlfJuOm9cI',
							dts : '06/09/2024 13:05:06',
							type : 'CHARGE',
							amount : 50,
							method : 'VISA 4242'
						},
					],
				},
				{
					id : 1002,
					cra_name : 'ClearStar',
					dts : new Date(),
					composite_status : 'In Progress',
					expiration_date :  " 23:59:59",
					package_descrip : 'Non New York State 1-Year Package with Continuous Monitoring',
					stripe_transactions : null,
				}
			]);
		}
		else
		{
			console.warn(response);
			setBgChecks([]);
		}
	}

	function ShowDetailsModal( appid )
	{
		let details = bgchecks.find( (bgcheck) => {
			return bgcheck.id === appid;
		});

		setOrderdetailsobject(details);
		setOrderdetailsmodal(true);
	}

	function HideDetailsModal( )
	{
		setOrderdetailsmodal(false);

		setRefundDetails({});
		setOverRefundError(false);
		setRefundbuttonactive(false);
		setDetailsrefundtype(false);
		setDetailsrefundtoggle(false);
		setOrderdetailsobject({});
		setRefundSuccess(false);
		setRefundinprogress(false);
	}

	function CurrencyFormat( event )
	{
		let validControlKeyCodes = [8,9,37,39,46];

		let newdetails = { ...refunddetails };

		if(newdetails.otherrefundamount === undefined || newdetails.otherrefundamount === null)
		{
			newdetails.otherrefundamount = '';
		}
		if(validControlKeyCodes.indexOf(event.keyCode) !== -1)
		{
			if(event.keyCode === 8 && newdetails.otherrefundamount && newdetails.otherrefundamount.length > 0)
			{
				newdetails.otherrefundamount = newdetails.otherrefundamount.substring(0,newdetails.otherrefundamount.length-1);

				if( parseFloat(orderdetailsobject.amount) - parseFloat(newdetails.otherrefundamount) < 0 )
				{
					setOverRefundError(true);
				}
				else
				{
					setOverRefundError(false);
				}
			

				setRefundDetails(newdetails);
			}

			//control keys like Tab, backspace or arrows
			return;
		}
		else if(event.key && !event.key.match(/[0-9.]/))
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}
	
		newdetails.otherrefundamount += event.key;

		if( parseFloat(orderdetailsobject.amount) - parseFloat(orderdetailsobject.refunded_amount) - parseFloat(newdetails.otherrefundamount) < 0 )
		{
			setOverRefundError(true);
		}
		else
		{
			setOverRefundError(false);
		}

		setRefundDetails(newdetails);
		CheckRefundStatus();
	}

	function CheckRefundStatus()
	{
		setRefundbuttonactive(false);

		if(orderdetailsrefundtoggle === false)
		{
			setRefundbuttonactive(false);
			setDetailsrefundtype(false);
		}
		else if(orderdetailsrefundtoggle && orderedtailsrefundtype === 'full' && parseFloat(orderdetailsobject.refunded_amount) === 0)
		{
			setRefundDetails({otherrefundamount : ''});

			setRefundbuttonactive(true);
		}
		else
		{
			if(orderdetailsrefundtoggle && parseFloat(refunddetails.otherrefundamount) > 0 && ( parseFloat(orderdetailsobject.amount) - parseFloat(orderdetailsobject.refunded_amount) - parseFloat(refunddetails.otherrefundamount) ) < 0 )
			{
				setOverRefundError(true);
				setRefundbuttonactive(false);
			}
			else if(orderdetailsrefundtoggle && parseFloat(refunddetails.otherrefundamount) > 0 && ( parseFloat(orderdetailsobject.amount) - parseFloat(orderdetailsobject.refunded_amount) - parseFloat(refunddetails.otherrefundamount) ) >= 0 )
			{
				setRefundbuttonactive(true);
			}
			else
			{
				setRefundbuttonactive(false);
			}
		}

	}

	async function SubmitRefund( event )
	{
		setRefundinprogress(true);
		event.preventDefault();

		let formData = new FormData(formRef.current);

		let refundrequest = { ...refunddetails };

		refundrequest.applicationid = orderdetailsobject.id;
		if(orderedtailsrefundtype === 'full')
		{
			refundrequest.refundamount = orderdetailsobject.amount;
		}
		else if(orderedtailsrefundtype === 'partial')
		{
			refundrequest.refundamount = refunddetails.otherrefundamount;
		}
		refundrequest.cancel_application = formData.get('cancel_application') === 'YES' ? 'YES' : 'NO';

		let response = await api.submitrefund(refundrequest);
		if(response.success)
		{
			let userid = hash.replace(/#([0-9]+)/,'$1');
			await LoadBgChecks( userid );

			setRefundbuttonactive(false);
			setRefundSuccess(true);
			setTimeout(() => {
				HideDetailsModal();
			},3000);
		}
		else
		{
			setRefundinprogress(false);
		}
	}

	async function DownloadClick( appid )
	{
		setDownloadModal(true);
		let response = await api.getReportDownloadLink( appid );

		if(response.success)
		{
			setDownloadlink(response.downloadlink);
		}
		else
		{
			setDownloaderror('Error: Report failure');
		}
	}


	async function DownloadReceipt( applicationid )
	{
		setDownloadModal(true);
		let response = await api.downloadreceipt( null , applicationid );
		if(response.success)
		{
			let now = parseInt(Date.now()/1000);
			setReceiptpdfname(`shieldhub_receipt_${now}.pdf`);
			setReceiptContent(`data:application/pdf;base64,${response.receipt_body}`);
		}
	}


	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setBgChecks([]);

		( async () => {
			await LoadBgChecks( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');

		if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadBgChecks( userid );
			})();
		}
	},[emitter.eventname,hash]);
	React.useEffect( () => {

		CheckRefundStatus();

	},[orderedtailsrefundtype,orderdetailsrefundtoggle,refunddetails.otherrefundamount]);

	/* eslint-enable react-hooks/exhaustive-deps */


	React.useEffect( () => {

		if(orderedtailsrefundtype === 'partial' && otherrefundamountref && otherrefundamountref.current && ( !refunddetails.otherrefundamount || refunddetails.otherrefundamount === '' ) )
		{
			otherrefundamountref.current.focus();
		}

	},[orderedtailsrefundtype,otherrefundamountref,refunddetails.otherrefundamount]);

	return (
		<>
			<Card className="p-4 d-inline-block">
				<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
					{panelshow && <ChevronDown/>}
					{!panelshow && <ChevronRight />}
					&nbsp;Background Checks</Button>


				<Collapse in={panelshow}>
					<Form>
						<Container style={{textAlign:'center'}} id="admin-background-check-list">
							{bgchecks && bgchecks.length > 0 && 
								<Row style={{color:branding.PrimaryColor,textTransform:'uppercase'}} className="p-4">
									<Col>Provider</Col>
									<Col>Package</Col>
									<Col>Start Date</Col>
									<Col>Status</Col>
									<Col>Exp Date</Col>
									<Col>Score</Col>
									<Col xs={1} style={{width:'100px'}}>Amounts</Col>
									<Col xs={1}></Col>
								</Row>
							}
							{bgchecks && bgchecks.map((bgcheck) => {

								return (<>
									<Row key={bgcheck.id} className="p-4 border-top">
										<Col>{bgcheck.cra_name}</Col>
										<Col style={{fontSize:'9pt'}}>{bgcheck.package_descrip}</Col>
										<Col>{(bgcheck.dts && dateValid(bgcheck.dts) ) ? dateFormat(bgcheck.dts,'MM/dd/yyyy') : null }</Col>
										<Col>{bgcheck.composite_status}</Col>
										<Col>{(bgcheck.expiration_date && dateValid(bgcheck.expiration_date) ) ? dateFormat(bgcheck.expiration_date,'MM/dd/yyyy') : null }</Col>
										<Col className="scorecolumn">
											{bgcheck.composite_status_code === 'done' && <ICScore score={bgcheck.currenticstatus} /> }
										</Col>
										<Col xs={1} style={{width:'100px'}} className="text-end" >
											$ {bgcheck.amount}<br />
											{bgcheck.stripe_transactions && bgcheck.stripe_transactions.length > 0 && <><Stripe size={15} style={{color:'rgb(98, 90, 250)',marginTop:'-2px'}} /> $ {bgcheck.paid_amount} </>}
										</Col>
										<Col xs={1}>
											<Dropdown>
												<Dropdown.Toggle variant="primary" size="sm"><Gear/></Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item onClick={ async () => {
														await DownloadClick(bgcheck.id);
													}} disabled={!(bgcheck.composite_status_code === 'done')} ><Download /> Download Report</Dropdown.Item>
													{bgcheck.stripe_transactions && bgcheck.stripe_transactions.length > 0 && <>
														<Dropdown.Item onClick={ async () => {
															await DownloadReceipt(bgcheck.id);
														}}><Receipt /> Download Receipt</Dropdown.Item>
														<Dropdown.Item onClick={() => {
															ShowDetailsModal(bgcheck.id);
														}} ><CurrencyDollar /> Payment Details / Refund</Dropdown.Item>
													</>}
												</Dropdown.Menu>
											</Dropdown>
										</Col>
									</Row>
								</>
								);
							})}
						</Container>
					</Form>
				</Collapse>
			</Card>
			<Modal show={ !( orderdetailsmodal === false ) } size="xl" centered onHide={() => { HideDetailsModal(); }}>
				<Form ref={formRef} onSubmit={SubmitRefund}>
					<Modal.Header closeButton>
						<Modal.Title>Order Transaction Details and Refund</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container className="p-3">
							<Row style={{color:branding.PrimaryColor,textTransform:'uppercase'}}>
								<Col xs={6}>PACKAGE DETAILS</Col>
								<Col xs={3}>DATE</Col>
								<Col >METHOD</Col>
								<Col className="text-end">AMOUNT</Col>
							</Row>
							<Row className="pt-1 pb-1">
								<Col xs={6}>{orderdetailsobject.package_descrip}</Col>
								<Col xs={3}>{(orderdetailsobject.dts && dateValid(orderdetailsobject.dts) ) ? dateFormat(orderdetailsobject.dts,'MM/dd/yyyy ppp') : null }</Col>
								<Col className="text-end">$ {orderdetailsobject.amount}</Col>
							</Row>
							<Row>
								<Col xs={6} style={{color:branding.PrimaryColor,textTransform:'uppercase'}}></Col>
								<Col xs={3}></Col>
								<Col style={{fontWeight:'bold'}} className="border-top pt-2">SUBTOTAL</Col>
								<Col xs={1} style={{fontWeight:'bold'}} className="text-end border-top pt-2">${orderdetailsobject.amount}</Col>
							</Row>
						</Container>
						<Container className="p-3 pt-0" >
							<Row >
								<Col xs={6} style={{color:branding.PrimaryColor,textTransform:'uppercase'}}>PAYMENTS &amp; CREDITS</Col>
								<Col xs={3}></Col>
							</Row>
							{orderdetailsobject?.stripe_transactions?.length > 0 && orderdetailsobject.stripe_transactions.map( (transaction) => {

								return (
									<Row key={transaction.id} className="pt-1">
										<Col xs={6}>{`${transaction.type} (Trans ID: ${transaction.id}) `}</Col>
										<Col xs={3}>
											{(transaction.dts && dateValid(transaction.dts) ) ? dateFormat(transaction.dts,'MM/dd/yyyy ppp') : null }
										</Col>
										<Col>{transaction.method}</Col>
										<Col xs={1} className="text-end">
											{transaction.type === 'REFUND' ? <span className="text-danger">($ {transaction.amount})</span> : <>$ {transaction.amount}</> }
										</Col>
									</Row>);
							})}
							<Row className="pt-1">
								<Col xs={{offset:9}} style={{textTransform:'uppercase',fontWeight:'bold'}} className="pt-3 border-top">BALANCE DUE</Col>
								<Col className="text-end pt-3 border-top" style={{fontWeight:'bold'}}>$ {( parseFloat(orderdetailsobject.amount) - parseFloat(orderdetailsobject.paid_amount) )}</Col>
							</Row>
						</Container>
						<Container className="p-3 rounded-1" style={{backgroundColor:'#F8F9FA'}}>
							<Row>
								<Col>
									<Form.Check type="checkbox" label={<Form.Check.Label role='button'>ISSUE REFUND</Form.Check.Label>} id="issue_refund_toggle" checked={orderdetailsrefundtoggle} onClick={(e) => {
										setDetailsrefundtoggle(e.target.checked);
									}} role='button' inline disabled={!( orderdetailsobject.amount > orderdetailsobject.refunded_amount )} onChange={() => {} } />
								</Col>
								{orderdetailsrefundtoggle && <Col xs={{offset:1}}>
									<Form.Check type="radio" name="refund_type" id="refund_type_full" label={<Form.Check.Label role='button'>Issue Full Refund (${orderdetailsobject.amount})</Form.Check.Label>} checked={(orderedtailsrefundtype === 'full' )} onChange={() => {
										setDetailsrefundtype('full');
									}} disabled={!( orderdetailsobject.refunded_amount === 0 )} role='button' />
									<InputGroup style={{width:'350px'}}>
										<Form.Check type="radio" name="refund_type" id="refund_type_partial" label={<Form.Check.Label role='button'>Other Amount</Form.Check.Label>} inline style={{marginTop:'8px'}} role='button' checked={(orderedtailsrefundtype === 'partial')} onChange={() => {
											setDetailsrefundtype('partial');
										}} /><InputGroup.Text>$</InputGroup.Text><Form.Control ref={otherrefundamountref} onKeyDown={CurrencyFormat} value={refunddetails.otherrefundamount} disabled={(orderedtailsrefundtype !== 'partial')} isInvalid={overrefunderror}  style={{textAlign:'right'}}/>
									</InputGroup>
									<p className="pt-3">If background check has not been started, check this box to cancel the pending background check</p>
									<Form.Check type="checkbox" name="cancel_application" id="cancel_application" label='Cancel Application' value='YES' defaultChecked={true} />
								</Col>}
							</Row>
						</Container>
						<Alert variant="danger" transition={false} show={orderdetailsrefundtoggle} className="m-3"><ExclamationTriangleFill /> Issuing a refund on an active background check will expire the user&apos;s ShieldScore. </Alert>
						<Alert variant="danger" transition={false} show={overrefunderror} className="m-3" ><ExclamationTriangleFill /> Error: Total refund amount cannot exceed original charge amount </Alert>
						<Alert variant="success" show={refundsuccess} className="m-3" ><CheckCircleFill /> Refund Successful </Alert>
					</Modal.Body>
					<Modal.Footer className="justify-content-between">
						<Button variant="link" onClick={() => { HideDetailsModal(); }} style={{textDecoration:'none',color:branding.PrimaryColor}} >Close</Button>
						<Button variant="primary" type='submit' disabled={!(refundbuttonactive) || refundinprogress } > { ( refundinprogress === true ? <>Processing ...</> : <>Issue Refund</> ) }</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<FileDownloadModal
				show={downloadModal}
				downloadlink={ (downloadlink !== false && downloadlink.length > 10 ? downloadlink : ( (receiptpdfcontenturl !== false && receiptpdfcontenturl.length > 10) ? receiptpdfcontenturl : false )  ) }
				downloadtext={ (downloadlink !== false && downloadlink.length > 10 ? 'Download Report' : ( (receiptpdfcontenturl !== false && receiptpdfcontenturl.length > 10) ? 'Download Receipt' : null )  ) }
				downloaderror={downloaderror}
				downloadname={receiptpdfname}
				CancelMethod={() => {
					setDownloadModal(false);
					setDownloadlink(false);
					setReceiptContent(false);
				}}
			/>
		</>
	);

}
export default BackgroundChecks;