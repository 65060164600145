import * as React from "react";
import { Col , Row , Form , InputGroup , Card } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
//import useDerivedState from "@twipped/hooks/useDerivedState";
import { useBranding } from "../Branding";
import { useAuth } from "../Auth";
import profileapi from "../../io/profile";
import userapi from '../../io/user.js';
import { useCookies } from "react-cookie";
import SearchResultsModal from "./SearchResultsModal";

function SearchShieldID() {

	const branding = useBranding();
	const auth = useAuth();
	const [ cookies ] = useCookies(['sh_linkid']);
	// const [ freesearchcount , setFreesearchcount ] = useDerivedState(() => ( parseInt(auth.user?.searchcount) > 0 ? (10 - parseInt(auth.user.searchcount)) : 10 ),[auth.user?.searchcount]);
	const [ showSearchResultsModal , setShowSearchModal ] = React.useState(false);
	const [ searchresults , setSearchresults ] = React.useState({});
	const [ prepopsearchterm , setSearchTerm ] = React.useState(null);
	
	const searchfieldref = React.createRef();

	async function ProfileSearch( term=null )
	{
		let searchterm = '';
		if(searchfieldref && searchfieldref.current)
		{
			searchterm = searchfieldref.current.value;
		}
		else if(prepopsearchterm)
		{
			searchterm = prepopsearchterm;
		}
		else if(term && term.length > 0)
		{
			searchterm = term;
		}

		if(searchterm && searchterm.length > 0)
		{
			let response = await profileapi.search( searchterm );
			if(response.freesearchexceeded === true)
			{
				// setFreesearchcount(0);
			}
			else if(response.success)
			{
				setSearchresults(response.searchresults);
				setShowSearchModal(true);
			}
			await auth.checkExistingSession();
		}
	}

	async function handleSearchControlkeys(event)
	{
		if(event.key === 'Enter')
		{
			event.preventDefault();
			await ProfileSearch();
		}
	}

	function DismissSearchModal() {
		setShowSearchModal(false);
		setSearchresults({});
	}

	async function GetLink( linkid )
	{
		let response = await userapi.getlink_w_auth( linkid );
		if(response.success && response.details.linktype === 'shieldid' && response.details.shieldid)
		{
			if(searchfieldref && searchfieldref.current)
			{
				searchfieldref.current.value = response.details.shieldid;
			}
			else
			{
				setSearchTerm(response.details.shieldid);

			}
			ProfileSearch(response.details.shieldid);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			searchfieldref.current.value = 'CA920571030';
			ProfileSearch();
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		if(cookies.sh_linkid !== undefined && cookies.sh_linkid !== null && cookies.sh_linkid !== '')
		{
			( async () => {
				await GetLink( cookies.sh_linkid );
			})();
		}

	},[cookies.sh_linkid]);
	/* eslint-enablereact-hooks/exhaustive-deps */

	return (

		<>

			<Card className="card-blue p-4">
				<Row>
					<Col md={{span:6}}>
						<h3>Search ShieldID<sup>&#8480;</sup></h3>
						<p>Were you provided a ShieldID<sup>&#8480;</sup> (ABC#)? Search here to locate and verify a ShieldScore<sup>TM</sup>.</p>
					</Col>
					<Col md={{span:6}} className="d-flex align-items-center">
						<Form>
							<InputGroup style={{width:"100%"}}>
								<Form.Control 
									ref={searchfieldref} 
									size="lg" 
									type="text" 
									style={{height:"60px"}} 
									onKeyDown={handleSearchControlkeys}
									defaultValue={prepopsearchterm}
								/>
								<InputGroup.Text id="search-addon" style={{cursor:"pointer",backgroundColor:branding.PrimaryButtonBackground,borderColor:branding.PrimaryButtonBackground,paddingLeft:"25px",paddingRight:"25px"}} onClick={ProfileSearch}><Search color={branding.PrimaryButtonText} size={33}/></InputGroup.Text>
							</InputGroup>
						</Form>
						{/*
						disable upgrade for now
						<a href="/upgrade" style={{float:"right",color:"#FFF",textDecorationLine:"none"}}> <ShieldFill style={{marginTop:"-5px"}}/> Upgrade to ShieldHub Pro</a>
						<p style={{float:"right",paddingRight:"20px",color:branding.PrimaryColor}}>{freesearchcount} Free Searches Left</p>
						*/}
					</Col>
				</Row>
			</Card>
			
			<SearchResultsModal 
				searchresults={searchresults}
				DismissSearchModal={DismissSearchModal}
				showSearchResultsModal={showSearchResultsModal}
			/>
		</>

	);

}

export default SearchShieldID;