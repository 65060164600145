import * as React from "react";
import {Button, Card , Row, Col, Alert , Dropdown } from "react-bootstrap";
import {format as dateFormat , parseJSON as dateParseJSON} from "date-fns";
import { Download, QuestionCircleFill, Receipt} from "react-bootstrap-icons";
import api from "../../io/profile";
import workflowapi from '../../io/workflow';
import { useBranding } from "../Branding";
import { useRefreshEmitter } from "../RefreshEmitter";
import ICScore from "../ICScore";
import FileDownloadModal from '../FileDownloadModal';


function MyBackgroundChecks() {
	
	const branding = useBranding();
	const emitter = useRefreshEmitter();

	const labelstyle = {
		color:branding.PrimaryColor,
	};


	const [ backgroundchecklist , setBackgroundchecklist ] = React.useState([]);
	const [ downloadModal , setDownloadModal ] = React.useState(false);
	const [ downloadlink , setDownloadlink ] = React.useState(false);
	const [ downloaderror , setDownloaderror ] = React.useState(false);
	const [ receiptpdfcontenturl , setReceiptContent ] = React.useState(false);
	const [ receiptpdfname , setReceiptpdfname ] = React.useState('shieldhub_receipt.pdf');

	async function RefreshBackgroundCheckList()
	{

		let response = await api.listapplications();


		if(response.success && response.applications.length > 0)
		{
			setBackgroundchecklist(response.applications);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setBackgroundchecklist([
				{
					id : 1,
					package : 'CRA',
					provider : 'ClearStar',
					composite_status_code : 'done',
					composite_status : 'Completed',
					expiration_dts : '2024-05-01T11:00:00',
					icscore : 'IC01'
				}
			]);
		}
		else if(response.success && response.applications.length === 0)
		{
			emitter.Emit('newapplicationcheck');
		}
	}

	async function DownloadClick( appid )
	{
		setDownloadModal(true);
		let response = await api.getDownloadLink( appid );

		if(response.success)
		{
			setDownloadlink(response.downloadlink);
		}
		else
		{
			setDownloaderror('Error: Report failure');
		}
	}

	async function DownloadReceipt( appid )
	{
		setDownloadModal(true);
		let response = await workflowapi.downloadreceipt( null , appid );
		if(response.success)
		{
			let now = parseInt(Date.now()/1000);
			setReceiptpdfname(`shieldhub_receipt_${now}.pdf`);
			setReceiptContent(`data:application/pdf;base64,${response.receipt_body}`);
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		( async () => {
			await RefreshBackgroundCheckList();
		})();

	},[]);

	React.useEffect( () => {

		// Watch for events to trigger refresh methods
		if( ['newapplicationcheck','globalrefresh'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await RefreshBackgroundCheckList();
			})();
		}
	},[emitter.eventname,emitter.data]);	
	/* eslint-enable react-hooks/exhaustive-deps */


	return ( 
		<>
			<Card className="p-4">
				<Card.Title>
					<h4>My Background Checks</h4>
					<p className="small">Below are a list of current and historical background checks. Background checks that are in progress are currently with the Consumer Reporting Agency (CRA) provider.  Questions about the status, status of the report, or resulting ShieldScore<sup>TM</sup> can be directed to the specified CRA provider.</p>
				</Card.Title>
				<Card.Body className="cra-card">
					{backgroundchecklist.map( (app) => {
						return (
							<>
								<Row className="bc-card" key={app.id}>
									<Col xs={12} md={12} lg={4}><label style={labelstyle} className="cra-package">Package</label>{app.package}</Col>
									<Col md={4} xs={6} lg={2}><label style={labelstyle} className="cra-package">Provider</label>{app.provider}</Col>
									{app.composite_status_code !== 'done' && 
										<Col md={4} xs={6} lg={2}><label style={labelstyle} className="cra-package">Status</label>{app.composite_status}</Col>
									}
									{ (app.composite_status_code === 'done') && 
										<Col md={4} xs={6} lg={2} className="score-column"><label style={labelstyle} className="cra-package">ShieldScore <a className="align-items-center" href="https://support.shieldhub.com/hc/en-us/articles/21734150168859-What-is-a-ShieldScore" target="_blank"><QuestionCircleFill/></a></label ><ICScore score={app.icscore}/></Col>
									}
									{ ( app.composite_status_code === 'newrequiresapproval' || app.composite_status_code === 'monitorrequiresapproval' ) && 
										<Col md={4} xs={6} lg={2} className="score-column"><label style={labelstyle} className="cra-package">ShieldScore <a className="align-items-center" href="https://support.shieldhub.com/hc/en-us/articles/21734150168859-What-is-a-ShieldScore" target="_blank"><QuestionCircleFill/></a></label ><ICScore score='NA'/></Col>
									}
									{ (app.composite_status_code !== 'done' && app.composite_status_code !== 'newrequiresapproval' && app.composite_status_code !== 'monitorrequiresapproval' ) && 
										<Col md={4} xs={6} lg={2}><label className="cra-package" style={labelstyle}>Order Date</label>{app.ordered && dateFormat(dateParseJSON(app.ordered),"MM/dd/yyyy")}</Col>
									}
									{ (app.composite_status_code === 'newrequiresapproval' || app.composite_status_code === 'monitorrequiresapproval' ) && 
										<Col md={4} xs={6} lg={2}><label className="cra-package" style={labelstyle}>Exp. Date</label>Pending Approval</Col>
									}
									{ (app.composite_status_code === 'done') && 
										<Col md={4} xs={6} lg={2}><label className="cra-package" style={labelstyle}>Exp. Date</label>{app.expiration_dts && dateFormat(dateParseJSON(app.expiration_dts),"MM/dd/yyyy")}</Col>
									}
									{app.composite_status_code === 'paid' && 
										<Col md={5} xs={6} lg={2}><Button size="sm" className="bc-button" variant="primary" href={( app.workflowstep !== null ? app.workflowstep : `#startapplication=${app.id}` )}>Start Your Report</Button></Col>
									}
									{app.composite_status_code === 'done' && 
											<Col  md={4} xs={6} lg={2}  >
												<Dropdown>
													<Dropdown.Toggle size="sm" className="bc-button" variant="primary">Download</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item onClick={ async () => {
															await DownloadClick(app.id);
														}}><Download /> Download Report</Dropdown.Item>
														{app.stripe_transaction_id && <Dropdown.Item onClick={ async () => {
															await DownloadReceipt(app.id);
														}} ><Receipt /> Download Receipt</Dropdown.Item>}
													</Dropdown.Menu>
												</Dropdown>
											</Col>
									}
									{app.composite_status_code === 'waituser' && 
										<Col md={5} xs={6} lg={2}><Button size="sm" className="bc-button bc-button-resume" variant="primary" href={`#buybackgroundcheck=${app.packageid}`}>Continue Application</Button></Col>
									}
								</Row>
								{app.composite_status_code === 'monitorrequiresapproval' && 
									<Alert variant="danger" className="m-2">{app.provider} has indicated a potential score change for your account. To protect your information, your previous score has been expired and network sharing disabled. To reenable your account, you must review and accept the revised score. For more information, visit our <a href="https://support.shieldhub.com/hc/en-us/sections/16453075011483-Background-Checks" target="_blank">help center</a>. </Alert>
								}
								{app.composite_status_code === 'newrequiresapproval' && 
									<Alert variant="danger" className="m-2">{app.provider} has indicated that your ShieldScore&trade; is completed and ready for your approval. You must accept your ShieldScore, so it can be released into your account. Once released you will be able to accept network invites and share your score. For more information, visit our <a href="https://support.shieldhub.com/hc/en-us/sections/16453075011483-Background-Checks" target="_blank">help center</a>. </Alert>
								}
								{app.composite_status_code === 'clearidaction' && 
									<Alert variant="danger" className="m-2">{app.provider} has indicated that you have ClearID Verification error.  This could be for several  reasons, including the image quality of your ID or selfie. For more information, visit our <a href="https://support.shieldhub.com/hc/en-us/sections/16453075011483-Background-Checks" target="_blank">help center</a>. </Alert>
								}
							</>
						)
					})
					}
				</Card.Body>
			</Card>
			<FileDownloadModal
				show={downloadModal}
				downloadlink={ (downloadlink !== false && downloadlink.length > 10 ? downloadlink : ( (receiptpdfcontenturl !== false && receiptpdfcontenturl.length > 10) ? receiptpdfcontenturl : false )  ) }
				downloadtext={ (downloadlink !== false && downloadlink.length > 10 ? 'Download Report' : ( (receiptpdfcontenturl !== false && receiptpdfcontenturl.length > 10) ? 'Download Receipt' : null )  ) }
				downloaderror={downloaderror}
				downloadname={receiptpdfname}
				CancelMethod={() => {
					setDownloadModal(false);
					setDownloadlink(false);
					setReceiptContent(false);
				}}
			/>
		</>
	);
}

export default MyBackgroundChecks;