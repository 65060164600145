import * as React from 'react';
import { Container , Card, Stack , Row , Col , Button , Badge , Modal, Form, InputGroup } from 'react-bootstrap';
import { BoxArrowUpRight, CalendarCheck, EnvelopeCheckFill, GearWideConnected, Search, ShieldFillCheck, ShieldFillX, XCircleFill } from 'react-bootstrap-icons';
import { format as dateFormat , parseJSON as dateParse , endOfToday , subDays , addDays , differenceInWeeks , differenceInMonths , isBefore } from "date-fns";
import ICScore from '../../components/ICScore';
import { AgGridReact } from 'ag-grid-react';
import { AgChartsReact } from 'ag-charts-react';
import { time } from "ag-charts-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from "@ag-grid-community/csv-export";
import ReactDatePicker from 'react-datepicker';
import MultiRangeSlider from "multi-range-slider-react";
import api from '../../io/network';
import { useBranding } from '../Branding';
import { useRefreshEmitter } from '../RefreshEmitter';

ModuleRegistry.register(CsvExportModule);

function Alerts()
{

	const alert_type_map = {
		'new' : {
			title : 'New',
			color : '#AEBD20',
			badgestyle : 'badge-success',
			description : 'The user has joined your network.'
		},
		'expiressoon' : {
			title : 'Expires Soon',
			color : '#CA6510',
			badgestyle : 'badge-warning',
			description : "The user's ShieldScore will expire within the next 30 days"
		},
		'scorechange' : {
			title : 'Score Changes',
			color : '#B02A37',
			badgestyle : 'badge-danger',
			description : 'A ShieldPulse monitoring score change'
		},
		'expired' : {
			title : 'Expired',
			color : '#B02A37',
			badgestyle : 'badge-danger',
			description : 'The current ShieldScore has expired and is no longer valid'
		},
		'leftnetwork' : {
			title : 'Left Network',
			color : '#B02A37',
			badgestyle : 'badge-danger',
			description : 'The user has elected to leave your network'
		},
		'removeduser':{
			title : 'Removed User',
			color : '#B02A37',
			badgestyle : 'badge-danger',
			description : 'You removed a user from your network'
		},
	};

	const csvexportdefaults = {
		columnKeys : [
			'alerttype',
			'alertdate_formatted',
			'userfullname',
			'usercompanyname',
			'useremail',
			'shieldid',
			'shieldid',
			'expirationdate_formatted',
			'previousicscore',
			'currenticscore'
		],
		fileName : 'alerts_'+(Math.floor(Date.now()/1000))
	};

	const branding = useBranding();
	const emitter = useRefreshEmitter();

	const [ alertsdata , setAlertsData ] = React.useState([]);
	const [ alertConfig , setAlertConfig ] = React.useState(false);
	const [ selectedProfile , setSelectedProfile ] = React.useState(false);
	const [ startDateRange , setStartDateRange ] = React.useState(subDays(new Date(),30));
	const [ endDateRange , setEndDateRange ] = React.useState(endOfToday());

	const [ showConfigModal , setShowConfigModal ] = React.useState(false);
	const [ showSubscriptionModal , setShowSubscriptionModal ] = React.useState(false);
	const [ showComplianceModal , setShowComplianceModal ] = React.useState(false);
	const [ showProfileModal , setShowProfileModal ] = React.useState(false);
	const [ saveInProgress , setSaveInProgress ] = React.useState(false);

	const alertGridRef = React.createRef(null);
	const configModalFormRef = React.createRef(null);
	const subscriptionModalFormRef = React.createRef(null);
	const complianceModalFormRef = React.createRef(null);

	const alert_grid_searchRef = React.createRef(null);
	const [ alertgrid_qf_text , setalertgrid_qf_text ] = React.useState('');

	const [ CompliancePercentGraph , setCompliancePercentGraph ] = React.useState({
		data : [],
		title : {
			text : 'ComplianceGuard Alerts',
			color:'#181D1F',
			fontSize: 14,
			fontFamily: 'Roboto',
			textAlign: 'left',
			padding: '0px',
		},
		background: {
			fill: 'rgba(255,255,255,0)',
		},
		series : [
			{
				type : 'donut',
				innerRadiusRatio : 0.7,
				angleKey : 'total',
				legendItemKey : 'label',
				showInLegend: true,
				calloutLabel : {
					enabled : false,
				},
				fills : [
					'#AEBE20',
					'#BE2026',
				],
				innerLabels : [
					{
						text : '% COMPLIANCE',
						fontSize: 10
					},
					{
						text : null,
						fontSize: 24,
						fontFamily: 'Roboto-Bold'
					}
				]
			},
		],
		legend : {
			position : 'bottom',
			maxWidth: 150,
			item : {
				paddingX: 10,
				marker : {
					padding : 5,
					shape: 'circle',
					size: 10,

				},
				label: {
					fontSize: 10,
				}
			}
		}
	});

	const [ AlertActivitybyTypeGraph , setAlertActivitybyTypeGraph ] = React.useState({
		data : [],
		title : {
			text : 'Alerts Activity by Type',
			color:'#181D1F',
			fontSize: '14',
			fontFamily: 'Roboto',
			textAlign: 'left',
			padding: '0px',
		},
		background: {
			fill: 'rgba(255,255,255,0)',
		},
		series : [
			{
				type : 'donut',
				innerRadiusRatio : 0.7,
				angleKey : 'total',
				legendItemKey : 'label',
				fills : [
					'#EC4899',
					'#f39d2a',
					'#f9cb34',
					'#25b7a6',
					'#25b7a6',
					'#25b7a6',
				],
				calloutLabel : {
					enabled : false,
				},
				innerLabels : [
					{
						text : 'TOTAL ALERTS',
						fontSize: 10
					},
					{
						text : null,
						fontSize: 24,
						fontFamily: 'Roboto-Bold'
					}
				]
			}
		],
		legend : {
			position : 'bottom',
			item : {
				paddingX: 10,
				marker : {
					padding : 5,
					shape: 'circle',
					size: 10,

				},
				label: {
					fontSize: 10,
				}
			}
		}
	});

	const [ AlertActivitybyDateGraph , setAlertActivitybyDateGraph ] = React.useState({
		data : [],
		title : {
			text : 'Alerts Activity by Date',
			color:'#181D1F',
			fontSize:'14',
			fontFamily: 'Roboto',
			textAlign: 'left',
			padding: '0px',
		},
		background: {
			fill: 'rgba(255,255,255,0)',
		},
		axes : [
			{
				type : 'time',
				position : 'bottom',
				tick : {
					interval : time.day
				},
				label : {
					rotation : -60,
					fontSize: 12,
					fontFamily: 'Roboto'
				}
			},
			{
				type : 'number',
				position : 'left',
				label : {
					fontSize: 12,
					fontFamily:'Roboto',
				}
			}
		],
		series : [
			{
				type : 'bar',
				stacked : true,
				xKey : 'dts',
				yKey : 'new',
				yName : 'New',
				legendItemName : 'New'
			},
			{
				type : 'bar',
				stacked : true,
				xKey : 'dts',
				yKey : 'scorechange',
				yName : 'Score Change',
				legendItemName : 'Score Change'
			},
			{
				type : 'bar',
				stacked : true,
				xKey : 'dts',
				yKey : 'expired',
				yName : 'Expired',
				legendItemName : 'Expired'
			},
			{
				type : 'bar',
				stacked : true,
				xKey : 'dts',
				yKey : 'expiressoon',
				yName : 'Expires Soon',
				legendItemName : 'Expires Soon'
			},
			{
				type : 'bar',
				stacked : true,
				xKey : 'dts',
				yKey : 'leftnetwork',
				yName : 'Left Network',
				legendItemName : 'Left Network'
			},
			{
				type : 'bar',
				stacked : true,
				xKey : 'dts',
				yKey : 'removeduser',
				yName : 'Removed User',
				legendItemName : 'Removed User'
			},
		],
		legend : {
			position : 'right',
			fontFamily: 'Roboto',
			item : {
				paddingX: 10,
				marker : {
					padding : 5,
					shape:'circle',
					size: 10,
				},
				label: {
					fontSize: 10,
				}

			}
		}
	});




	const alertRowRules = {
		'alerts-danger-row' : (params) => {
			return params.data.statusicon === 'fail';
		},
	};

	const alertGridAutosizeStrategy = {
		type: 'fitGridWidth',
	};

	const alertColumnDef = [
		{ field : 'statusicon', headerName : 'status', minWidth: '60', maxWidth: '60', filter : true , headerComponent :  () => { return ( <ShieldFillCheck size={20} /> ) } , cellRenderer : (props) => {

			if(props.data.statusicon === 'success')
			{
				return <ShieldFillCheck size={20} color='#AEBD20' />;
			}
			else if(props.data.statusicon === 'fail')
			{
				return <ShieldFillX size={20} color='#B02A37' />;
			}

		} },
		{ field : 'alerttype', minWidth: 120, headerName : 'Alert Type' , filter : true  , cellRenderer : (props) => {

			if(alert_type_map[props.data.alerttype])
			{
				return <Badge className={alert_type_map[props.data.alerttype].badgestyle}>{alert_type_map[props.data.alerttype].title}</Badge>
			}

		}},
		{ field : 'alertdate_formatted', headerName : 'Alert Date' , filter : 'agDateColumnFilter'},
		{ field : 'userfullname' , headerName : 'Legal Name' , minWidth: '140', filter : true },
		{ field : 'usercompanyname', headerName : 'Company' , filter : true },
		{ field : 'useremail' , headerName : 'Email' , filter : true },
		{ field : 'shieldid' , headerName : 'ShieldID' , filter : true },
		{ field : 'expirationdate_formatted', headerName : 'Exp Date' , filter : 'agDateColumnFilter' , cellRenderer : (props) => {

			if(props.data.alerttype === 'expired')
			{
				return ( <b style={{color:'#BE2026'}}>{props.value}</b> );
			}
			else if(props.data.alerttype === 'expiressoon')
			{
				return ( <b style={{color:'#997404'}}>{props.value}</b> );
			}
			else
			{
				return props.value;
			}

		}},
		{ field : 'previousicscore', minWidth: 150, headerName : 'Previous Score' , cellRenderer : (props) => {

			if(props.data.alerttype === 'expired' && props.data.currenticscore && props.data.currenticscore.match(/IC0[1-4]\+?/))
			{
				return <ICScore score={props.data.currenticscore} />;
			}
			else if(props.value && props.value.match(/IC0[1-4]\+?/))
			{
				return <ICScore score={props.value} />;
			}
		}},
		{ field : 'currenticscore', minWidth: 150, headerName : 'Current Score' , filter : true , cellRenderer : (props) => {

			if(props.data.alerttype === 'expired')
			{
				return <ICScore />;
			}
			else
			{
				return <ICScore score={props.value} />;
			}

		}},
	];

	const profileGridAutosizeStrategy = {
		type : 'fitGridWidth',
	};

	const profileHistoryColumnDef = [
		{ field : 'statusicon' , maxWidth : 52 , headerName : 'status', headerComponent :  () => { return ( <ShieldFillCheck size={20} /> ) } , cellRenderer : (props) => {

			if(props.data.statusicon === 'success')
			{
				return <ShieldFillCheck size={20} color='#AEBD20' />;
			}
			else if(props.data.statusicon === 'fail')
			{
				return <ShieldFillX size={20} color='#B02A37' />;
			}

		} },
		{ field : 'alerttype' , maxWidth : 150 , headerName : 'Alert Type' , cellRenderer : (props) => {

			if(props.data.alerttype === 'new')
			{
				return <Badge className='badge-success' >New</Badge>;
			}
			else if(props.data.alerttype === 'expiressoon')
			{
				return <Badge className='badge-warning' >Expiring Soon</Badge>;
			}
			else if(props.data.alerttype === 'expired')
			{
				return <Badge className='badge-danger' >Expired</Badge>;
			}
			else if(props.data.alerttype === 'scorechange')
			{
				return <Badge className='badge-danger' >Score Change</Badge>;
			}

		}},
		{ field : 'alertdate_formatted', headerName : 'Alert Date' , filter : 'agDateColumnFilter'},
		{ field : 'expirationdate_formatted' , headerName : 'Exp Date' , filter : 'agDateColumnFilter' , cellRenderer : (props) => {

			if(props.data.alerttype === 'expired')
			{
				return ( <b style={{color:'#BE2026'}}>{props.value}</b> );
			}
			else if(props.data.alerttype === 'expiressoon')
			{
				return ( <b style={{color:'#997404'}}>{props.value}</b> );
			}
			else
			{
				return props.value;
			}

		}},
		{ field : 'previousicscore' , width: 155, maxWidth : 160, headerName : 'Previous Score' , cellRenderer : (props) => {

			if(props.data.alerttype === 'expired' && props.data.currenticscore && props.data.currenticscore.match(/IC0[1-4]\+?/))
			{
				return <ICScore score={props.data.currenticscore} />;
			}
			else if(props.value && props.value.match(/IC0[1-4]\+?/))
			{
				return <ICScore score={props.value} />;
			}
		}},
		{ field : 'currenticscore', width: 155, maxWidth : 160, headerName : 'Current Score' , cellRenderer : (props) => {

			if(props.data.alerttype === 'expired')
			{
				return <ICScore />;
			}
			else
			{
				return <ICScore score={props.value} />;
			}

		}},
	];

	async function SelectAlertRow( props )
	{
		console.log(props);
		if(props.source !== 'apiSelectAll')
		{
			let response = await api.getalertprofile( props.data.userid );
			if(response.success)
			{
				setSelectedProfile(response.profile);
				setShowProfileModal(true);
			}
			else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
			{
				setSelectedProfile({
					firstname : 'Sam',
					lastname : 'Smith',
					shieldid : 'CA123451111',
					expirationdate_formatted : '12/31/2024',
					currenticscore : 'IC01+',
					profileimageurl : '',
					alerthistory : [
						{
							statusicon : 'success',
							alerttype : 'new',
							alertdate : new Date(),
							alertdate_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
							userfullname : 'John Doe',
							usercompanyname : 'ACME Inc.',
							useremail : 'john@example.com',
							shieldid : 'CA1234590000',
							expirationdate : new Date(),
							expirationdate_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy'),
							previousicscore : null,
							currenticscore : 'IC01'
						},						
					]
				});
				setShowProfileModal(true);
			}
			else
			{
				console.warn(response);
			}
		}
	}

	async function GetAlerts()
	{
		/* Start Raw Table Data */
		let alerts_array = [];

		let response = await api.listalerts( startDateRange , endDateRange );
		if(response.success)
		{
			if( !(response.alertconfig.complianceguard) || Object.keys(response.alertconfig.complianceguard).length === 0)
			{
				response.alertconfig.complianceguard = {
					minscore : 0,
					maxscore : 4,
				};
			}
			
			if( response.alertconfig.complianceguard && (response.alertconfig.complianceguard.minscore === null || response.alertconfig.complianceguard.minscore === undefined ) )
			{
				response.alertconfig.complianceguard.minscore = 0;
			}
			if( response.alertconfig.complianceguard && (response.alertconfig.complianceguard.maxscore === null || response.alertconfig.complianceguard.maxscore === undefined ) )
			{
				response.alertconfig.complianceguard.maxscore = 4;
			}

			if( !(response.alertconfig.includestatuses) || Object.keys(response.alertconfig.includestatuses).length === 0)
			{
				response.alertconfig.includestatuses = {
					new : true,
					expiressoon : true,
					expired : true,
					scorechange : true,					
				};
			}

			setAlertConfig(response.alertconfig);

			alerts_array = response.alerts;

			alerts_array = alerts_array.map( (item) => {

				if(item.alertdate)
				{
					try {
						item.alertdate = dateParse(item.alertdate);
					}
					catch (e){
						console.warn(e);
						item.alertdate = null;
					}
				}
				return item;
			});

			setAlertsData(alerts_array);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setAlertConfig({
				complianceguard : {
					minscore : 0,
					maxscore : 1,
					outofcomp_expired : true,
					outofcomp_leftnetwork : false,
					outofcomp_removed : true,
				},
				includestatuses : {
					new : false,
					expiressoon : true,
					expired : true,
					scorechange : true,
				},
				subscription : {
					daily_summary : true,
					network_invites : false,
					expirations : true,
					purchase : false,
					scorechange : true,
					left_network : false,
					out_compliance : true,
				},
			});

			alerts_array = [
				{
					statusicon : 'success',
					alerttype : 'new',
					alertdate : new Date(),
					alertdate_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					userid : '1000',
					userfullname : 'John Doe',
					usercompanyname : 'ACME Inc.',
					useremail : 'john@example.com',
					shieldid : 'CA1234590000',
					expirationdate : new Date(),
					expirationdate_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy'),
					previousicscore : null,
					currenticscore : 'IC01'
				},
				{
					statusicon : 'success',
					alerttype : 'expiressoon',
					alertdate : new Date(),
					alertdate_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					userid : '1000',
					userfullname : 'John Doe',
					usercompanyname : 'ACME Inc.',
					useremail : 'john@example.com',
					shieldid : 'CA1234590000',
					expirationdate : new Date(),
					expirationdate_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy'),
					previousicscore : null,
					currenticscore : 'IC01'
				},
				{
					statusicon : 'fail',
					alerttype : 'expired',
					alertdate : new Date(),
					alertdate_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					userid : '1000',
					userfullname : 'John Doe',
					usercompanyname : 'ACME Inc.',
					useremail : 'john@example.com',
					shieldid : 'CA1234590000',
					expirationdate : new Date(),
					expirationdate_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy'),
					previousicscore : null,
					currenticscore : 'IC01'
				},
				{
					statusicon : 'fail',
					alerttype : 'scorechange',
					alertdate : new Date(),
					alertdate_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					userid : '1000',
					userfullname : 'John Doe',
					usercompanyname : 'ACME Inc.',
					useremail : 'john@example.com',
					shieldid : 'CA1234590000',
					expirationdate : new Date(),
					expirationdate_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy'),
					previousicscore : 'IC01',
					currenticscore : 'IC04'
				},
			];
			setAlertsData(alerts_array);
		}
		else
		{
			console.warn(response);
			setAlertsData([]);
		}



		/* End Raw Table Data */
		/* Start ComplianceGuard Alerts Graph */

		let updateComplianceData = { ...CompliancePercentGraph };

		let successalerts = alerts_array.reduce( ( sum , current ) => {

			if(current.statusicon === 'success')
			{
				return sum + 1;
			}
			else
			{
				return sum;
			}

		},0);
		let failalerts = alerts_array.reduce( ( sum , current ) => {

			if(current.statusicon === 'fail')
			{
				return sum + 1;
			}
			else
			{
				return sum;
			}

		},0);

		updateComplianceData.data = [
			{ total : successalerts , label : 'Compliant' },
			{ total : failalerts , label : 'Non-compliant' },
		];

		if((successalerts + failalerts) === 0)
		{
			updateComplianceData.series[0].innerLabels[1].text = '%';
		}
		else
		{
			updateComplianceData.series[0].innerLabels[1].text = ( (successalerts / (successalerts + failalerts)) * 100 ).toFixed(0) + '%';
		}

		setCompliancePercentGraph(updateComplianceData);
		/* End ComplianceGuard Alerts Graph */

		/* Start Alert Activity by Type Graph */

		let updateAlertActivitybyTypeGraph = { ...AlertActivitybyTypeGraph };

		let alert_count = {};

		alerts_array.forEach( (item) => {

			if(alert_count[item.alerttype])
			{
				alert_count[item.alerttype] += 1;
			}
			else
			{
				alert_count[item.alerttype] = 1;
			}
		});

		updateAlertActivitybyTypeGraph.data = Object.keys(alert_count).map( (key) => {
			return {
				label : alert_type_map[key].title,
				total : alert_count[key],
			};
		});

		updateAlertActivitybyTypeGraph.series[0].innerLabels[1].text = alerts_array.length.toString();

		setAlertActivitybyTypeGraph(updateAlertActivitybyTypeGraph);
		/* End Alert Activity by Type Graph */

		/* Start Alert Activity by Date Graph */

		if(startDateRange !== null && endDateRange !== null )
		{
			let updateAlertActivitybyDateGraph = { ...AlertActivitybyDateGraph };

			updateAlertActivitybyDateGraph.data = [];

			for( let currentdts = startDateRange ; currentdts < endDateRange ;  )
			{
				let date_bucket_end = addDays(currentdts,1);

				let alerts_within_range = alerts_array.filter( (item) => {

					return item.alertdate >= currentdts && item.alertdate < date_bucket_end;

				});

				let alert_breakdown = {
					new : 0,
					expired : 0,
					expiressoon : 0,
					scorechange : 0,
				};

				alerts_within_range.forEach( (item) => {

					if(alert_breakdown[item.alerttype])
					{
						alert_breakdown[item.alerttype] += 1;
					}
					else
					{
						alert_breakdown[item.alerttype] = 1;
					}
				});

				updateAlertActivitybyDateGraph.data.push({ ...alert_breakdown , dts : currentdts });

				currentdts = addDays(currentdts,1);
			}

			if(Math.abs(differenceInMonths(startDateRange,endDateRange)) >= 2)
			{
				updateAlertActivitybyDateGraph.axes[0].tick.interval = time.month;
			}
			else if(Math.abs(differenceInWeeks(startDateRange,endDateRange)) >= 2)
			{
				updateAlertActivitybyDateGraph.axes[0].tick.interval = time.week;
			}

			setAlertActivitybyDateGraph(updateAlertActivitybyDateGraph);
		}
		/* End Alert Activity by Date Graph */

	}

	function handleDateRangeChange(dates)
	{
		const [ start , end ] = dates;
		setStartDateRange(start);
		setEndDateRange(end);
	}

	function ComplianceRangeUpdate( newvalue )
	{
		let new_alert_config = { ...alertConfig };

		new_alert_config.complianceguard.minscore = newvalue.minValue;
		new_alert_config.complianceguard.maxscore = newvalue.maxValue;

		setAlertConfig(new_alert_config);
	}

	async function SaveAlertConfig()
	{
		setSaveInProgress(true);

		let new_alert_config = { ...alertConfig };

		if(configModalFormRef.current)
		{
			let configForm = new FormData(configModalFormRef.current);

			Object.keys(alert_type_map).forEach( (item) => {

				console.log(configForm.get(`includestatuses_${item}`));

				if(configForm.get(`includestatuses_${item}`) && configForm.get(`includestatuses_${item}`) === 'on')
				{
					new_alert_config.includestatuses[item] = true;
				}
				else
				{
					new_alert_config.includestatuses[item] = false;
				}
			});
		}

		if(subscriptionModalFormRef.current)
		{
			let subscribeForm = new FormData(subscriptionModalFormRef.current);

			['daily_summary','network_invites','expirations','new','purchase','left_network','scorechange','out_compliance'].forEach( (item) => {
				if(subscribeForm.get(`subscription_${item}`) && subscribeForm.get(`subscription_${item}`) === 'on')
				{
					new_alert_config.subscription[item] = true;
				}
				else
				{
					new_alert_config.subscription[item] = false;
				}
			});
		}

		if(complianceModalFormRef.current)
		{
			let complianceForm = new FormData(complianceModalFormRef.current);

			['outofcomp_expired','outofcomp_leftnetwork','outofcomp_removed'].forEach( (item) => {
				if(complianceForm.get(`complianceguard_${item}`) && complianceForm.get(`complianceguard_${item}`) === 'on')
				{
					new_alert_config.complianceguard[item] = true;
				}
				else
				{
					new_alert_config.complianceguard[item] = false;
				}
			});
		}

		setAlertConfig(new_alert_config);

		let response = await api.savealertconfig(new_alert_config);
		if(response.success)
		{
			setShowSubscriptionModal(false);
			setShowConfigModal(false);
			setShowComplianceModal(false);
		}
		else
		{
			console.warn(response);
		}

		setSaveInProgress(false);
		GetAlerts();
		emitter.Emit('globalrefresh');
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		GetAlerts();

	}, [startDateRange,endDateRange]);

	React.useEffect(() => {

		( async () => {
			await GetAlerts();
		})();

		let alertRefresh = setInterval( async () => {
			await GetAlerts();
		},5 * 60 * 1000);

		return () => {
			clearInterval(alertRefresh);
		}

	}, []);
	/* eslint-disable react-hooks/exhaustive-deps */

	return (
		<>
			<Stack gap={2} className='p-3 alerts-page'>
				
				<Container>
					<Row>
						<Col md={4}>
							<h4 className="mt-2">Alerts &amp; Notifications</h4>
						</Col>
						<Col sm={8} className="pe-0"style={{whiteSpace:'nowrap'}}>
							<div className="float-end mb-2 me-0">
								<Button variant='primary' size='md' className='me-1' onClick={() => {
									setShowSubscriptionModal(true);
								}}><EnvelopeCheckFill /> Subscribe</Button>
								<Button variant='primary' size='md' className='me-1' onClick={() => {
									setShowComplianceModal(true);
								}}><ShieldFillCheck /> ComplianceGuard</Button>
								<Button variant='primary' size='md' className='me-1' onClick={() => {
									setShowConfigModal(true);
								}}><GearWideConnected /></Button>
							</div>
							<div className="float-end">
								<ReactDatePicker
									showIcon
									selectsRange
									startDate={startDateRange}
									endDate={endDateRange}
									onChange={handleDateRangeChange}
									customInput={ <input className='me-1' style={{paddingTop:'6px',paddingBottom:'7px', border: 'solid 1px #D2D2D2',borderRadius: '6px',fontSize:'14px',width:'200px',textAlign:'right'}} /> }
									icon={<CalendarCheck style={{marginTop:'2px'}}/>}
									maxDate={ endOfToday() }
								/>
							</div>
						</Col>
					</Row>
				
				</Container>
				<Container>
					<Row>
						<Col md={6} lg={3} className='mb-2'>
							<Card style={{height: '300px'}}>
								<AgChartsReact options={CompliancePercentGraph} />
							</Card>
						</Col>
				
						<Col md={6} lg={3} className='mb-2'>
							<Card style={{height: '300px'}}>
								<AgChartsReact options={AlertActivitybyTypeGraph} />
							</Card>
						</Col>
						<Col sm={12} md={6} className="mb-2 d-none d-lg-block">
							<Card style={{height: '300px'}}>
								<AgChartsReact options={AlertActivitybyDateGraph} />
							</Card>
						</Col>
					</Row>
				</Container>
				
				<Container>
					<Row>
						<Col className='mt-2'>
							<h4 style={{fontSize: '18px'}}>Network Activity Feed</h4>
						</Col>
						<Col xs={6} className='content-end'>
							<InputGroup className="mb-1 network-sharedwithme-quickfilter" style={{float:'right'}}>
								<Form.Control 
									ref={alert_grid_searchRef}
									type='text' 
									name="sharedwithmequickfilter" 
									placeholder="Search"
									onInput={(e) => {
										setalertgrid_qf_text(e.target.value);
									}}
								/>
								<InputGroup.Text>
									<Search className="searchbutton" size={12} />
									<Button className="clearbutton p-0" variant="link" onClick={() => { alert_grid_searchRef.current.value = '';  }}><XCircleFill style={{pointer:'cursor'}} color="grey"/></Button>
								</InputGroup.Text>
							</InputGroup>
						</Col>
					</Row>
					<div className="ag-theme-quartz" style={{height:'500px'}} >
						<AgGridReact 
							ref={alertGridRef}
							rowData={alertsdata} 
							rowClassRules={alertRowRules}
							rowStyle={{cursor:'pointer'}}
							columnDefs={alertColumnDef}
							pagination={true}
							quickFilterText={alertgrid_qf_text}
							rowSelection='single'
							onRowSelected={SelectAlertRow}
							autoSizeStrategy={alertGridAutosizeStrategy}
						/>
					</div>
					<Row className='p-0'>
						<Col xs={{span:4,offset:8}} className='content-end'>
							<Button variant='link' style={{color:branding.PrimaryButtonBackground,textDecoration:'none',float:'right'}} onClick={() => {

								alertGridRef.current.api.exportDataAsCsv(csvexportdefaults);

							}}>Export as CSV <BoxArrowUpRight size={12} style={{marginTop:'-5px'}} /></Button>
						</Col>
					</Row>
				</Container>
			</Stack>
			<Modal show={showConfigModal} size='lg' centered={true} className='alerts-page' onHide={()=> {
				setShowConfigModal(false);
			}}>
				<Modal.Header>
					<Modal.Title>Alert Configuration</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form ref={configModalFormRef}>
						<p>Configure which notifications you wish to track. </p>

						{ Object.keys(alert_type_map).map( (item) => {

							return (
								<Row key={item} className='pb-2'>
									<Col xs={{offset:2,span:1}} style={{width:'10px',marginTop:'2px'}}>
										<Form.Check inline={true} id={`includestatuses_${item}`} name={`includestatuses_${item}`} defaultChecked={ alertConfig?.includestatuses && alertConfig?.includestatuses[item] && alertConfig?.includestatuses[item] === true } />
									</Col>
									<Col xs={2}>
										<Form.Check.Label for={`includestatuses_${item}`} style={{width:'100%'}}>
											<Badge className={alert_type_map[item].badgestyle} >{alert_type_map[item].title}</Badge>
										</Form.Check.Label>
									</Col>
									<Col><Form.Check.Label for={`includestatuses_${item}`}>{alert_type_map[item].description}</Form.Check.Label></Col>
								</Row>
							);
						})}
					</Form>
				</Modal.Body>
				<Modal.Footer className='justify-content-between'>
					<Button variant='link' style={{textDecoration:'none',color:'var(--primary-color,#ED683C)'}} onClick={()=> {
						setShowConfigModal(false);
					}}>Cancel</Button>
					<Button onClick={SaveAlertConfig} disabled={saveInProgress}>{(saveInProgress ? 'Saving...' : 'Save')}</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showSubscriptionModal} size='lg' centered={true} className='alerts-page'>
				<Modal.Header>
					<Modal.Title>Alert Subscription</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form ref={subscriptionModalFormRef}>
						<p>Deliver a <b>Daily Alert Summary</b> to your mailbox daily.  Choose the alert topics you would like included in your subscription.  You may change or cancel your subscription at any time.</p>

						<Row>
							<Col xs={{offset:3,span:4}}><Form.Check id='subscription_network_invites' name='subscription_network_invites' inline={true} label='Network Invites' defaultChecked={ alertConfig?.subscription?.network_invites && alertConfig?.subscription?.network_invites === true } /></Col>
							<Col xs={5}><Form.Check id='subscription_expirations' name='subscription_expirations' inline={true} label='Expirations' defaultChecked={ alertConfig?.subscription?.expirations === true } /></Col>
						</Row>
						<Row>
							<Col xs={{offset:3,span:4}}><Form.Check id='subscription_new' name='subscription_new' inline={true} label='New/Joining Network' defaultChecked={ alertConfig?.subscription?.new === true } /></Col>
							<Col xs={5}><Form.Check id='subscription_purchase' name='subscription_purchase' inline={true} label='ShieldScore Purchase Statuses' defaultChecked={ alertConfig?.subscription?.purchase === true }/></Col>
						</Row>
						<Row>
							<Col xs={{offset:3,span:4}}><Form.Check id='subscription_left_network' name='subscription_left_network' inline={true} label='Network Departures' defaultChecked={ alertConfig?.subscription?.left_network === true }/></Col>
							<Col xs={5}><Form.Check id='subscription_scorechange' name='subscription_scorechange' inline={true} label='Score Changes' defaultChecked={ alertConfig?.subscription?.scorechange === true }/></Col>
						</Row>
						<Row>
							<Col xs={{offset:3,span:4}}><Form.Check id='subscription_out_compliance' name='subscription_out_compliance' inline={true} label='Out of Compliance Vendors' defaultChecked={ alertConfig?.subscription?.out_compliance === true }/></Col>
						</Row>

						<Form.Check id='subscription_daily_summary' name='subscription_daily_summary' defaultChecked={ alertConfig?.subscription?.daily_summary && alertConfig.subscription.daily_summary === true } label={<><b>Yes email me my daily alert summary.</b> I understand I can change my preference at any time.</>} />
					</Form>
				</Modal.Body>
				<Modal.Footer className='justify-content-between'>
					<Button variant='link' style={{textDecoration:'none',color:'var(--primary-color,#ED683C)'}} onClick={()=> {
						setShowSubscriptionModal(false);
					}}>Cancel</Button>
					<Button onClick={SaveAlertConfig} disabled={saveInProgress}>{(saveInProgress ? 'Saving...' : 'Save')}</Button>					
				</Modal.Footer>
			</Modal>
			<Modal show={showComplianceModal} size='lg' centered={true} className='compliance-modal alerts-page'>
				<Modal.Header>
					<Modal.Title>ComplianceGuard&trade;</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form ref={complianceModalFormRef}>
						<p><b>ComplianceGuard</b> allows you to set minimum compliance standards to make it easier to identify vendors that are out of compliance.</p>

						<Row>
							<Col xs={{offset:1,span:3}}>Allowed Score Range</Col>
							<Col xs={7}>
								<MultiRangeSlider
									min={0}
									max={4}
									step={1}
									minValue={alertConfig?.complianceguard?.minscore}
									maxValue={alertConfig?.complianceguard?.maxscore}
									labels={[
										<ICScore key={0} score='IC01+' />,
										<ICScore key={1} score='IC01' />,
										<ICScore key={2} score='IC02' />,
										<ICScore key={3} score='IC03' />,
										<ICScore key={4} score='IC04' />,
									]}
									onChange={ComplianceRangeUpdate}
									canMinMaxValueSame={true}
								/>
							</Col>
						</Row>
						<Row className='compliance_statuses mt-5'>
							<Col xs={{offset:1,span:3}}>Out of Compliance Statuses</Col>
							<Col xs={1} className='compliance_input'>
								<Form.Check inline id='complianceguard_outofcomp_expired' name='complianceguard_outofcomp_expired' defaultChecked={alertConfig?.complianceguard?.outofcomp_expired} /><br />
								<Form.Check inline id='complianceguard_outofcomp_leftnetwork' name='complianceguard_outofcomp_leftnetwork' defaultChecked={alertConfig?.complianceguard?.outofcomp_leftnetwork} /><br />
								<Form.Check inline id='complianceguard_outofcomp_removed' name='complianceguard_outofcomp_removed' defaultChecked={alertConfig?.complianceguard?.outofcomp_removed} /><br />
							</Col>
							<Col xs={4} className='compliance_labels'>
								<Form.Check.Label for='complianceguard_outofcomp_expired'>
									<Badge className='badge-danger'>Expired</Badge>
								</Form.Check.Label>
								<Form.Check.Label for='complianceguard_outofcomp_leftnetwork'>
									<Badge className='badge-danger'>Left Network</Badge>
								</Form.Check.Label>
								<Form.Check.Label for='complianceguard_outofcomp_removed'>
									<Badge className='badge-danger'>Removed Contractor</Badge>
								</Form.Check.Label>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer className='justify-content-between'>
					<Button variant='link' style={{textDecoration:'none',color:'var(--primary-color,#ED683C)'}} onClick={()=> {
						setShowComplianceModal(false);
					}}>Cancel</Button>
					<Button variant="primary" onClick={SaveAlertConfig} disabled={saveInProgress}>{(saveInProgress ? 'Saving...' : 'Save')}</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showProfileModal} className='alerts-profile-page' centered={true} size='lg' onHide={() => {
				setShowProfileModal(false);
				alertGridRef.current.api.deselectAll();
			}}>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col xs={3}>
							{selectedProfile.profileimageurl && selectedProfile.profileimageurl.length > 20 && <div className="profile-image-frame" style={{marginLeft:'auto',marginRight:'auto',width:'125px',height:'125px',backgroundImage:'url("'+selectedProfile.profileimageurl+'")'}} />}
							{( !(selectedProfile.profileimageurl) || selectedProfile.profileimageurl.length === 0) && <div className="profile-image-frame" style={{borderWidth:'5px',width:'125px',height:'125px',marginLeft:'auto',marginRight:'auto',backgroundImage:'url("/images/user-icon.png")'}} /> }
						</Col>
						<Col className='mt-2'>
							<h5>{selectedProfile.firstname} {selectedProfile.lastname}</h5>
							ShieldID : {selectedProfile.shieldid}<br />
							Expires : {selectedProfile.expirationdate_formatted}<br />
							<div style={{marginTop:'5px'}}>{ isBefore(selectedProfile.expirationdate,new Date()) ? <ICScore /> : <ICScore score={selectedProfile.currenticscore} /> }</div>
						</Col>
					</Row>
					<div className="mt-4 ag-theme-quartz alerts-page compliance-modal" style={{height:'320px',minWidth:'700px'}} >
						<AgGridReact
							rowData={selectedProfile.alerthistory}
							rowClassRules={alertRowRules}
							pagination={true}
							columnDefs={profileHistoryColumnDef}
							autoSizeStrategy={profileGridAutosizeStrategy}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => {
						setShowProfileModal(false);
						alertGridRef.current.api.deselectAll();
					}}>Close History</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
export default Alerts;