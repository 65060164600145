import * as React from "react";
import { Helmet , HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider , RequiresAuth , RequiresAdmin} from "./components/Auth";
import { RefreshEmitterProvider } from "./components/RefreshEmitter.js";
import { BrandingProvider , BrandingCSS } from "./components/Branding";
import Login from "./routes/Login";
import TwoStepVerify from './routes/TwoStepVerify.js';
import Logout from './routes/Logout';
import User from "./routes/User";
import Admin from "./routes/Admin";
import RegisterVerification from "./routes/RegisterVerification";
import Register from "./routes/Register";
import RegisterTandC from "./routes/RegisterTandC";
import ForgotPassword from "./routes/ForgotPassword";
import ForgotPasswordVerification from "./routes/ForgotPasswordVerification";
import ForgotNewPassword from "./routes/ForgotNewPassword";
import Footer from "./components/Footer";
import DeepLink from "./routes/DeepLink.js";
import PaymentTerms from './routes/PaymentTerms';
import { AwsRum } from 'aws-rum-web';
import PendingInvites from "./routes/PendingInvites.js";

if(process.env.REACT_APP_RUM_ID !== null && process.env.REACT_APP_RUM_ID !== undefined)
{
	try {
		const config = {
			sessionSampleRate: 1,
			guestRoleArn: process.env.REACT_APP_RUM_ROLE,
			identityPoolId: process.env.REACT_APP_RUM_POOL_ID,
			endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
			telemetries: [
				"performance",
				"errors",
				[ 'http', { addXRayTraceIdHeader: true } ]
			],
			allowCookies: true,
			enableXRay: true
		};
	
		const APPLICATION_ID = process.env.REACT_APP_RUM_ID;
		const APPLICATION_VERSION = '1.0.0';
		const APPLICATION_REGION = 'us-east-1';
	
		/* eslint-disable no-unused-vars */
		const awsRum = new AwsRum(
			APPLICATION_ID,
			APPLICATION_VERSION,
			APPLICATION_REGION,
			config
		);
		/* eslint-enable no-unused-vars */
	} catch (error) {
		// Ignore errors thrown during CloudWatch RUM web client initialization
	}
}

function HardRedirect(params) {

	window.location = params.to;

	return (<></>);
}

function App() {

	const debugUserPages = (process.env.REACT_APP_LOCALDEBUG ? true : false);

	return (

		<HelmetProvider>
			<AuthProvider>
				<RefreshEmitterProvider>
					<BrandingProvider>
						<BrandingCSS />
						<Helmet>
							<title>ShieldHub</title>
						</Helmet>
						<BrowserRouter>
							<Routes>
								<Route path="/" element={<Navigate to='/login' />} />
								<Route path="/api" element={<HardRedirect to='/api/index.html' />} />
								<Route path="/login" element={<Login />} />
								<Route path="/logout" element={<Logout />} />
								{debugUserPages && <Route path="/verify" element={<TwoStepVerify />} />}
								{ !(debugUserPages) && <Route path="/verify" element={<RequiresAuth><TwoStepVerify /></RequiresAuth> } />}
								<Route path="/forgotpassword" element={<ForgotPassword />} />
								<Route path="/forgotpassword/verification" element={<ForgotPasswordVerification />} />
								<Route path="/forgotpassword/newpassword" element={<ForgotNewPassword />} />
								<Route path="/register" element={<Register />} />
								<Route path="/register/terms" element={<RequiresAuth><RegisterTandC /></RequiresAuth>} />
								<Route path="/register/verification" element={<RequiresAuth><RegisterVerification /></RequiresAuth>}/>
								{debugUserPages && <Route path="/user/:panel?/:workflowstep?" element={<User />}/>}
								{ !(debugUserPages) && <Route path="/user/:panel?/:workflowstep?" element={<RequiresAuth><User /></RequiresAuth>}/>}

								{debugUserPages && <Route path="/admin/:panel?" element={<Admin />} />}
								{ !(debugUserPages) && <Route path="/admin/:panel?" element={<RequiresAdmin><Admin /></RequiresAdmin>}/>}
								<Route path="/link/:linkid" element={<DeepLink />} />

								<Route path="/pendinginvites/:invitecode" element={<PendingInvites />} />

								<Route path="/paymentterms" element={<PaymentTerms />} />

								<Route path="*" element={<Navigate to='/login' />} />
							</Routes>
							<Footer />
						</BrowserRouter>
					</BrandingProvider>
				</RefreshEmitterProvider>
			</AuthProvider>
		</HelmetProvider>
	);
}

export default App;
