import * as React from "react";
import { Stack , Card , Row , Col , Button} from "react-bootstrap";
import { Download, PlayFill } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import { useBranding } from "../../Branding";
import WhiteShieldCheck from '../../../assets/white_shield_check.svg';
import ClearStarLogo from "../../../assets/cra_logos/clearstar.png";
import api from '../../../io/workflow';
import {format as dateFormat } from "date-fns";

function PuchaseReceipt() {

	const { hash } = useLocation();
	const branding = useBranding();
	const [ purchasedetails , setPurchasedetails ] = React.useState(false);
	const [ packagedetails , setPackagedetails ] = React.useState(false);
	const [ applicationdetails , setApplicationDetails ] = React.useState(false);
	const [ workflowstep , setWorkflowstep ] = React.useState(false);
	const [ receiptpdfcontenturl , setReceiptContent ] = React.useState(false);
	const [ receiptpdfname , setReceiptpdfname ] = React.useState('shieldhub_receipt.pdf');

	async function LoadPurchaseDetails( purchaseid )
	{

		let response = await api.getpurchase( purchaseid );
		if(response.success)
		{
			if(response.purchasedetails.created)
			{
				response.purchasedetails.created_formatted = dateFormat( parseInt(response.purchasedetails.created) * 1000 ,'MM/dd/yyyy');
			}

			setPurchasedetails(response.purchasedetails);
			setPackagedetails(response.packagedetails);
			setApplicationDetails(response.application);
			setWorkflowstep(response.workflowstep);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			let now = Date.now();
			let now_formatted = dateFormat(now, 'MM/dd/yyyy');

			setPurchasedetails({
				amount : 5000,
				created : now,
				created_formatted : now_formatted
			});
		}
	}

	async function DownloadReceipt( purchaseid )
	{
		let response = await api.downloadreceipt( purchaseid );
		if(response.success)
		{
			let now = parseInt(Date.now()/1000);
			setReceiptpdfname(`shieldhub_receipt_${now}.pdf`);
			setReceiptContent(`data:application/pdf;base64,${response.receipt_body}`);
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {
		window.scrollTo(0, 0);

		if(hash.match(/#purchaseid=/))
		{
			if(hash.match(/#purchaseid=.+/))
			{
				var hashvalues = hash.match(/#purchaseid=(.+)/);

				( async () => {
					await LoadPurchaseDetails(hashvalues[1]);
					await DownloadReceipt(hashvalues[1]);
				})();

			}
		}
		else
		{
			//reset values here
			setPurchasedetails(false);
			setPackagedetails(false);
			setApplicationDetails(false);
			setReceiptContent(false);
		}
	},[ hash ]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<Stack gap={3} className="pb-5 col-xs-12 col-sm-8 col-md-6 mx-auto">
			<Card className="pt-3 ps-3 pe-3" style={{backgroundColor:'#2A2D40',color:'#FFFFFF'}}>
				<Card.Body>
					<Row>
						<Col xs={12} className="text-center pb-5">
							<img src={WhiteShieldCheck} height="130" alt="White Shield Check" />
						</Col>
					</Row>
					<Row>
						<Col xs={12} style={{fontSize:'26px'}} className="align-self-center">
							<h3>Now let&apos;s get started!</h3>
							<p>Proceed to the next step to start your background check report.</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button variant="outline-light" href="/user" style={{color:'#FFFFFF',textDecoration:'none'}}>Maybe Later</Button>
						</Col>
						<Col className="text-end">
							<Button variant="primary" href={( workflowstep !== false ? workflowstep : `/user#startapplication=${applicationdetails.id}` )} >Start Your Report&nbsp;<PlayFill /></Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card>
				<Card.Body>
					<b style={{fontSize:'16pt'}}>Receipt</b>
					<Row className="pt-3">
						<Col xs={6} style={{color:branding.PrimaryColor}}>
							PROVIDER
						</Col>
						<Col>
							<img src={ClearStarLogo} alt="ClearStar CRA" style={{width:'190px'}}/>
						</Col>
					</Row>
					<Row className="pt-3">
						<Col xs={6} style={{color:branding.PrimaryColor}}>
							ORDER DETAILS
						</Col>
						<Col>
							{packagedetails.package_descrip}
						</Col>
					</Row>
					<Row className="pt-3">
						<Col xs={6} style={{color:branding.PrimaryColor}}>
							ORDER DATE
						</Col>
						<Col>
							{purchasedetails.created_formatted}
						</Col>
					</Row>
					<Row className="pt-3">
						<Col xs={6} style={{color:branding.PrimaryColor}}>
							TOTAL AMOUNT
						</Col>
						<Col xs={2}>
							${( purchasedetails.amount  ? (purchasedetails.amount/100) : null )}
						</Col>
						<Col xs={4} className="text-end">
							<Button disabled={receiptpdfcontenturl === false} variant="link" href={receiptpdfcontenturl} download={receiptpdfname} style={{color:branding.PrimaryButtonBackground,textDecoration:'none'}}><Download /> Receipt </Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Stack>
	);
}
export default PuchaseReceipt;