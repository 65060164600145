import * as React from "react";
import { Button, Card, Col , Row, Nav , Tab, Form, Modal, Dropdown, InputGroup, Badge } from "react-bootstrap";
import { ArrowClockwise, CheckCircleFill, PersonFillAdd, TrashFill, BoxArrowUpRight, Diagram3Fill, ShareFill, Search, XCircleFill, GearFill, Download } from "react-bootstrap-icons";
import { useBranding } from "../Branding";
import ICScore from "../ICScore";
import api from "../../io/network";
import profileapi from '../../io/profile';
import { format as dateFormat , parseJSON as dateParse } from "date-fns";
import { useRefreshEmitter } from "../RefreshEmitter";
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from "@ag-grid-community/csv-export";
import ShareMyShieldScore from "./ShareShieldScore";
import { useAuth } from "../Auth";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import FileDownloadModal from '../FileDownloadModal';

ModuleRegistry.register(CsvExportModule);

function MyNetwork(){

	const branding = useBranding();
	const emitter = useRefreshEmitter();
	const auth = useAuth();

	const rows_per_page = 10;

	const [ sharedconnections , setSharedConnections ] = React.useState([]);
	const [ connectionssharedwithme , setConnSharedWithMe ] = React.useState([]);
	const [ profile , setProfile ] = React.useState({});
	const [ inviteResent , setInviteResent ] = React.useState(false);

	const [ deleteConnection , setDeleteConnection ] = React.useState({});

	const [ downloadModal , setDownloadModal ] = React.useState(false);
	const [ downloadlink , setDownloadlink ] = React.useState(false);
	const [ downloaderror , setDownloaderror ] = React.useState(false);
	const [ downloadname , setDownloadname ] = React.useState(false);

	const sharedwithmegridref = React.createRef(false);
	const csvexportdefaults = {
		columnKeys : [
			'firstname',
			'lastname',
			'businessname',
			'emailaddress',
			'share_dts_formatted',
			'abcnumber',
			'currenticstatus',
			'expiration_dts_formatted'
		],
		fileName : 'myconnections_'+(Math.floor(Date.now()/1000))
	};
	const filterdefaults = {
		buttons : [ 'reset' ]
	};
	const [ shwme_qf_text , setshwme_qf_text ] = React.useState('');
	const shwme_qf_ref = React.createRef(false);


	/*
	ShieldScore header component to figure out how to make work
	headerComponent : () => {
			return 	<>ShieldScore&nbsp;<a href="https://support.shieldhub.com/hc/en-us/articles/21734150168859-What-is-a-ShieldScore-" target="_blank"><QuestionCircleFill/></a></>;
		}
	*/

	const sharedwithmeColDef = [
		{ field : 'fullname' , headerName : 'Name' , filter : true , filterParams : filterdefaults ,cellRenderer : (props) => {
			return <>{props.data.firstname} {props.data.lastname}</>;
		}},
		{ field : 'firstname', headerName : 'First Name' , hide : true },
		{ field : 'lastname', headerName : 'Last Name' , hide : true },
		{ field : 'businessname' , headerName : 'Company' , filter : true , filterParams : filterdefaults },
		{ field : 'emailaddress' , headerName : 'Email' , filter : true , filterParams : filterdefaults },
		{ field : 'share_dts_formatted' , headerName : 'Share Date' , initialWidth : 130 , filter : 'agDateColumnFilter' , filterParams : filterdefaults},
		{ field : 'abcnumber' , headerName : 'ShieldID' , filter : true , filterParams : filterdefaults , initialWidth : 140 , cellStyle : { overflow:'visible' }, cellRenderer : (props) => {

			if(props.data.connectionstatus === 'INVITE')
			{
				return <div style={{textAlign:'center'}}>
					<Dropdown >
						<Dropdown.Toggle className="mb-1" size="sm" variant={(inviteResent && inviteResent === props.data.id ? 'success' : "outline-primary")}>{( inviteResent && inviteResent === props.data.id ? <><CheckCircleFill /> Invite Resent </> : <>Invite Actions</>)}</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item size="sm" onClick={() => {ResendInvite(props.data.id);}}><ArrowClockwise /> Resend Invite</Dropdown.Item>
							<Dropdown.Item size="sm" onClick={() => {CancelInvite(props.data.id);}} >Cancel Invite</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown></div>
			}
			else
			{
				return props.value;
			}

		} },
		{ field : 'currenticstatus' , headerName : 'ShieldScore' , filter : true , filterParams : filterdefaults , initialWidth : 174 ,  cellRenderer : (props) => {

			if(props.data.connectionstatus === 'APPSTARTED')
			{
				return <div style={{textAlign:'center'}}><Badge className="status_badges"  bg='white' text='dark'>Application Started</Badge></div>;
			}
			else if(props.data.connectionstatus === 'CHECKINPROGRESS')
			{
				return <div style={{textAlign:'center'}}><Badge className="status_badges" bg='white' text='dark'>In Progress</Badge></div>;
			}
			else if(props.data.connectionstatus === 'PENDINGINVITEACCEPT')
			{
				return <div style={{textAlign:'center'}} className="mt-1"><Badge className="status_badges" bg='white' text='dark'>Pending Invitation<br />Acceptance</Badge></div>;
			}
			else if(props.data.connectionstatus === 'EXPIRED')
			{
				return <div style={{textAlign:'center'}}><Badge className="status_badges" bg='white' text='dark'>Expired</Badge></div>;
			}
			else if(props.data.connectionstatus === 'INVITE' || props.data.connectionstatus === 'DISABLED')
			{
				return <div style={{textAlign:'center'}} className="mt-1" ><Badge className="status_badges" bg='white' text='dark'>Background Check<br />Required</Badge></div>;
			}
			else
			{
				return <ICScore score={props.value} />
			}
		}},
		{ field : 'expiration_dts_formatted' , headerName : 'Expiration Date' , filter : 'agDateColumnFilter' , initialWidth : 160 },
		{ field : '' , headerName : 'Actions' , initialWidth : 119 , cellStyle : { overflow:'visible' } , cellRenderer : (props) => {

			if( !(props.data.connectionstatus === 'INVITE' || props.data.connectionstatus === 'DISABLED') )
			{
				return <div style={{textAlign:'center'}}>
					<Dropdown >
						<Dropdown.Toggle className="mb-1" size="sm" variant={(inviteResent && inviteResent === props.data.id ? 'success' : "outline-primary")}><GearFill /> </Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item size="sm" onClick={() => {setDeleteConnection({connectionid : props.data.id, type : 'sharedwithme'});}}><TrashFill color="#BE2026"/> Remove</Dropdown.Item>
							{props.data.reportshared === 'YES' && <Dropdown.Item size="sm" onClick={() => { DownloadReport(props.data.id); }} > <Download /> Download Report</Dropdown.Item> }
						</Dropdown.Menu>
					</Dropdown></div>
			}
		}}
	];

	const sharedwithothersgridref = React.createRef(false);
	const sharedwithothersColDef = [
		{ field : 'fullname' , headerName : 'Name' , filter : true , initialWidth : 250 , filterParams : filterdefaults ,cellRenderer : (props) => {
			return <>{props.data.firstname} {props.data.lastname}</>;
		}},
		{ field : 'firstname', headerName : 'First Name' , hide : true },
		{ field : 'lastname', headerName : 'Last Name' , hide : true },
		{ field : 'businessname' , headerName : 'Company' , filter : true , initialWidth : 250 , filterParams : filterdefaults },
		{ field : 'share_dts_formatted' , headerName : 'Share Date' , initialWidth : 130 , filter : 'agDateColumnFilter' , filterParams : filterdefaults},
		{ field : '' , headerName : 'Enable/Disable' , initialWidth : 130 , cellRenderer : (props) => {
			return <div style={{textAlign:'center',paddingTop:'10px'}}>
				<Form.Check type="switch" checked={props.data.active === 'YES'} style={{transform:"scale(1.3)"}} onChange={() => {
					ToggleActive(props.data.id);
				}}/>
			</div>
		}},
		{ field : '' , headerName : 'Share Report PDF' , initialWidth : 142 , cellRenderer : (props) => {
			return <div style={{textAlign:'center',paddingTop:'10px'}}>
				<Form.Check type="switch" checked={props.data.reportshared === 'YES'} style={{transform:"scale(1.3)"}} onChange={() => {
					TogglePDFShared(props.data.id);
				}}/>
			</div>
		}},
		{ field : '' , initialWidth : 130, cellRenderer : (props) => {

			if(props.data.active !== 'YES')
			{
				return <Button variant="link" onClick={() => { setDeleteConnection({connectionid:props.data.id,type:'myshares'}) }}><TrashFill size={20} color="#BE2026"/></Button>
			}
		}}
	];

	const sharedwithothersAutosizeStrategy = {
		type: 'fitGridWidth',
	};
	const sharedwithmeAutosizeStrategy = {
		type: 'fitGridWidth',
	};

	// const TooltipLink = ({ id, children, title}) => (
	// 	<OverlayTrigger overlay={<Tooltip id={id} placement="placement">{title}</Tooltip>}>
	// 		<a href="#" className="condhelp">{children}</a>
	// 	</OverlayTrigger>
	// );

	
	async function RefreshProfile()
	{

		let response = await profileapi.getmyprofile();

		if(response.success)
		{
			setProfile(response.profile);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setProfile({
				network_searchresults : 'YES',
				network_sharing_master : 'YES',
			});
		}

	}

	async function RefreshConnections(){

		var response = await api.listconnections();

		if(response.success)
		{
			response.connections.sharedwithme = response.connections.sharedwithme.map( (item) => {

				item.fullname = `${item.lastname}, ${item.firstname}`;
				item.share_dts_formatted = dateFormat(dateParse( item.share_dts ), 'MM/dd/yyyy');

				if(item.expiration_date)
				{
					try{
						item.expiration_dts_formatted = dateFormat(dateParse( item.expiration_date ), 'MM/dd/yyyy');
					}
					catch {
						item.expiration_dts_formatted = null;
					}
				}

				// let disabled = (item.connectionstatus === 'DISABLED');

				return item;
			});

			response.connections.myshares = response.connections.myshares.map( (item) => {
				item.share_dts_formatted = dateFormat(dateParse( item.share_dts ), 'MM/dd/yyyy');
				return item;
			});

			setSharedConnections(response.connections.myshares);
			setConnSharedWithMe(response.connections.sharedwithme);
			await RefreshProfile();
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setConnSharedWithMe([
				{
					id : 1001,
					keyid : 1001,
					firstname : '',
					lastname : '',
					fullname : '',
					businessname : '',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'INVITE',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : null,
					purchase_date : new Date().toJSON(),
					reportshared : 'NO',
				},
				{
					id : 1000,
					keyid : 1000,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : 'MO630341030',
					currenticstatus : 'IC01',
					connectionstatus : 'CURRENT',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					purchase_date : new Date().toJSON(),
					reportshared : 'YES',
				},
				{
					id : 1001,
					keyid : 1001,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'APPSTARTED',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : null,
					purchase_date : new Date().toJSON(),
				},
				{
					id : 1001,
					keyid : 1001,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'CHECKINPROGRESS',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : null,
					purchase_date : new Date().toJSON(),
				},
				{
					id : 1001,
					keyid : 1001,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'PENDINGINVITEACCEPT',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : null,
					purchase_date : new Date().toJSON(),
				},
				{
					id : 1001,
					keyid : 1001,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'EXPIRED',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					purchase_date : new Date().toJSON(),
				},
			]);

			setSharedConnections([
				{
					id : 2000,
					keyid : 5200,
					firstname : 'Jane',
					lastname : 'Doe',
					fullname : 'Doe, Jane',
					businessname : 'Business Name',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy'),
					active : 'NO',
				}
			])
		}
		else
		{

		}
	}

	async function ToggleActive( connectionid )
	{
		let connections = sharedconnections;
		let active = '';

		connections = connections.map( (row) => {

			if(row.id === connectionid)
			{
				//Toggle 
				row.active = ( row.active === 'YES' ? 'NO' : 'YES');

				active = row.active;
			}
			return row;
		});

		setSharedConnections(connections);

		await api.setconnectionactive( connectionid , active );

	}

	async function TogglePDFShared( connectionid )
	{
		let connections = sharedconnections;
		let shared = '';

		connections = connections.map( (row) => {

			if(row.id === connectionid)
			{
				//Toggle
				row.reportshared = ( row.reportshared === 'YES' ? 'NO' : 'YES');

				shared = row.reportshared;
			}
			return row;
		});
		
		setSharedConnections(connections);

		await api.setsharedetails( connectionid , shared );
	}

	async function ResendInvite( inviteid )
	{
		let response = await api.resendinvite( inviteid );

		if(response.success)
		{
			setInviteResent(inviteid);
			setTimeout( () => {
				setInviteResent(false);
			},5000);
		}
	}

	async function CancelInvite( inviteid )
	{
		let response = await api.cancelinvite( inviteid );

		if(response.success)
		{
			setTimeout( () => {
				RefreshConnections();
			},500);
		}
	}

	async function DeleteSetConnection( )
	{
		if(deleteConnection.type === 'myshares')
		{
			let connections = sharedconnections;

			connections = connections.filter( (row) => {

				return !(row.id === deleteConnection.connectionid);
			});

			setSharedConnections(connections);

			await api.deleteconnection( deleteConnection.connectionid , deleteConnection.type );
		}
		else if(deleteConnection.type === 'sharedwithme')
		{
			let connections = connectionssharedwithme;

			connections = connections.filter( (row) => {

				return !(row.id === deleteConnection.connectionid);
			});

			setConnSharedWithMe(connections);

			await api.deleteconnection( deleteConnection.connectionid , deleteConnection.type );
		}

		setDeleteConnection({});
	}

	async function DownloadReport( connectionid )
	{
		setDownloadModal(true);

		let response = await api.downloadreport( connectionid );
		if(response.success)
		{
			setDownloadlink(response.downloadlink);
			setDownloadname('background_check_details.pdf');			
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setDownloadlink('https://www.google.com');
			setDownloadname('background_check_details.pdf');
		}
		else
		{
			setDownloaderror('Error: Report failure');
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		( async () => {
			await RefreshConnections();
		})();

	},[]);

	React.useEffect( () => {

		// Watch for events to trigger refresh methods
		if( ['networkupdate','globalrefresh'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await RefreshConnections();
			})();
		}		

	},[emitter.eventname,emitter.data]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<>
			<Card className="p-4">
				<Row>
					<Col sm={12}>
						<h4>My Network</h4>
					</Col>
					<Col xs={12} sm={8} lg={9} md={9} xl={10}>
						<p className="small mb-3">Use the tabs below to manage your trusted network. Use the <b>Shared With Me</b> tab to view and monitor your independent contractor network. Use the <b>Shared With Others</b> tab manage who you are sharing your ShieldScore<sup>TM</sup> with.  Click the <b>Network Actions Button</b> to send an invitation to join your network or share your ShieldScore.</p>
					</Col>
					
					<Col xs={2} sm={1} lg={1} className="float-end">
						<Dropdown>
							<Dropdown.Toggle variant="primary" id="dropdown-basic">
								<Diagram3Fill /> Network Actions
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item href="#addtomynetwork"><PersonFillAdd/> Invite User</Dropdown.Item>
								<Dropdown.Item 
									href="#sharemyshieldid"
									disabled={ ! ( auth?.user?.certification_number && auth?.user?.certification_number?.length > 0 && profile.network_sharing_master === 'YES' && profile.network_searchresults === 'YES') }
								><ShareFill /> Share My ShieldScore</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>

						{/* <Button style={{float:"left", whiteSpace:"nowrap"}} className="mt-2" href="#addtomynetwork"><PersonFillAdd/> Invite User</Button> */}
					</Col>
				</Row>
				<Row style={{marginTop:"20px",overflow:'clip'}} className="network-tabs">
					<Col sm={12}>
						<Tab.Container id="left-tabs-example" defaultActiveKey="sharedwithme">
							<Nav variant="tabs">
								<Nav.Item xs={6}>
									<Nav.Link eventKey="sharedwithme">Shared with Me</Nav.Link>
								</Nav.Item>
								<Nav.Item xs={6}>
									<Nav.Link eventKey="shared">Shared with Others</Nav.Link>
								</Nav.Item>
							</Nav>
							<Tab.Content style={{backgroundColor:"#FFFFFF"}}>
								<Tab.Pane eventKey="shared">
									<div id="network-sharewithothers" className="ag-theme-quartz" style={{height:'500px',minWidth:'1100px'}} >
										<AgGridReact 
											ref={sharedwithothersgridref}
											rowData={sharedconnections} 
											columnDefs={sharedwithothersColDef}
											pagination={true}
											paginationPageSize={rows_per_page}
											autoSizeStrategy={sharedwithothersAutosizeStrategy}
											noRowsOverlayComponent={()=> {
												return <>Start building your trusted network. Add independent contractors today</>;
											}}
										/>
									</div>
								</Tab.Pane>
								<Tab.Pane eventKey="sharedwithme">
									<div >
										<InputGroup className="mb-1 network-sharedwithme-quickfilter">
											<Form.Control 
												ref={shwme_qf_ref}
												type='text' 
												name="sharedwithmequickfilter" 
												placeholder="Quick Filter"
												onInput={(e) => {
													setshwme_qf_text(e.target.value);
												}}
											/>
											<InputGroup.Text>
												<Search className="searchbutton" size={12} />
												<Button className="clearbutton p-0" variant="link" onClick={() => { shwme_qf_ref.current.value = '';  }}><XCircleFill style={{pointer:'cursor'}} color="grey"/></Button>
											</InputGroup.Text>
										</InputGroup>
										
									</div>
									<div id="network-sharedwithme" className="ag-theme-quartz" style={{height:'500px',minWidth:'1100px'}} >
										<AgGridReact 
											ref={sharedwithmegridref}
											rowData={connectionssharedwithme} 
											columnDefs={sharedwithmeColDef}
											pagination={true}
											paginationPageSize={rows_per_page}
											quickFilterText={shwme_qf_text}
											autoSizeStrategy={sharedwithmeAutosizeStrategy}
											noRowsOverlayComponent={()=> {
												return <>Start building your trusted network. Add independent contractors today</>;
											}}
										/>
									</div>
									<Row className="p-0">
										<Col xs={{span:4,offset:8}} className="content-end">
											<Button variant="link" style={{color:branding.PrimaryButtonBackground,float:"right",textDecorationLine:"none"}} onClick={() => {

												sharedwithmegridref.current.api.exportDataAsCsv(csvexportdefaults);
												/*
												saveAsCsv({
													data : csvstruct.data,
													fields : csvstruct.fields,
													filename : csvstruct.filename
												})
												*/
											}}>Export as CSV <BoxArrowUpRight size={12} style={{marginTop:'-5px'}}/></Button>
										</Col>
									</Row>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</Col>
				</Row>
			</Card>
			<Modal show={(parseInt(deleteConnection.connectionid) > 0)} centered={true} size="sm">
				<Modal.Body style={{textAlign:'center'}}>
					<h5 style={{clear:'both'}}>Are you sure?</h5>
					<br />
					<div style={{float:'left'}}>
						<Button variant="outline-secondary" onClick={() => { setDeleteConnection({}); }}>Nevermind</Button>
					</div>
					<div style={{float:'right'}}>
						<Button variant="primary" onClick={DeleteSetConnection}>Yes</Button>
					</div>
				</Modal.Body>
			</Modal>
			<ShareMyShieldScore />
			<FileDownloadModal
				show={downloadModal}
				downloadlink={ (downloadlink !== false && downloadlink.length > 10 ? downloadlink : false ) }
				downloadtext={ (downloadlink !== false && downloadlink.length > 10 ? 'Download Report' : null ) }
				downloaderror={downloaderror}
				downloadname={downloadname}
				CancelMethod={() => {
					setDownloadModal(false);
					setDownloadlink(false);
				}}
			/>			
		</>
	);
}

export default MyNetwork;